import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Alert,
  Button,
  Grid,
  Paper,
  StackProps,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  stepContentClasses,
  stepLabelClasses,
  Tooltip,
  Typography,
} from "@mui/material";
import DonationTypeStep from "./Steps/DonationTypeStep";
import DonationAmountStep from "./Steps/DonationAmountStep";
import GiftRecipientStep, { GiftInfo } from "./Steps/GiftRecipientStep";
import ShippingStep, { ShippingInfo } from "./Steps/ShippingStep";
import UserInfoStep, { UserInfo } from "./Steps/UserInfoStep";
import PaymentStep, { PaymentMethod } from "./Steps/PaymentStep";
import GiftStep from "./Steps/GiftStep";
import Axios, { AxiosResponse } from "axios";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeCardNumberElement,
  StripeElements,
} from "@stripe/stripe-js";
import {
  CHECKOUT_ENDPOINT,
  CONTENTFUL_INITIAL_DONATION_TYPE_ID,
  NON_US_SHIPPING_FEE,
} from "Utils/Constants";
import History from "Utils/Routing/History";
import { IoArrowBack, IoInformationCircleOutline } from "react-icons/io5";
import { defaultGift } from "./DefaultGift";
import { DonationSummary } from "./DonationSummary";
import JGIcon from "../JGIcon";
import CheckoutStepperNav, {
  CheckoutStepperNavProps,
} from "Pages/Components/Checkout/CheckoutStepperNav";
import { formatCurrency } from "Utils/Format";
import { getDonationPlanCode } from "Utils/Components/Plan";
import { DonateQueryParamName, PaypalProps } from "../../../../types/Donate";
import { DonateQueryParam, DonationType } from "Utils/Constants/Donate";
import useErrorMessages from "Utils/ErrorMessages";
import ErrorCode from "Utils/Constants/ErrorCode";
import { parseQSValue } from "Utils/Routing/QueryString";
import { DEFAULT_DONATION_AMOUNTS } from "Pages/Components/Checkout/Constants";
import ConfirmationDialog from "../ConfirmationDialog";
import { PlanCode } from "../../../../types/Plan";
import ContentfulClient from "Utils/ContentfulClient";
import { useAuth0 } from "react-auth0-spa";
import moment from "moment";
import "../../../i18n/config";
import { getI18n, useTranslation } from "react-i18next";

interface ContentfulInitialDonationType {
  name: string;
  donationType: "monthly" | "one-time";
}

export interface CheckoutStep {
  key: number;
  label: string | Function;
  summary: Function;
  render: Function;
  valid: Function;
  rightComponent: RightComponent;
  transitionProps: Object;
  upsell: boolean;
}

const getQueryStringValue = (
  key: DonateQueryParamName,
  qs = window.location.search
) => {
  return parseQSValue(key, qs);
};

enum RightComponent {
  Info,
  Summary,
}

function getStartingDonationType() {
  const qsm = getQueryStringValue("m");
  const qsf = getQueryStringValue("f");
  if (qsf) {
    if (qsf === "m") {
      return DonationType.monthly;
    } else if (qsf === "a") {
      return DonationType?.oneTime;
    }
  } else if (qsm) {
    return DonationType.monthly;
  }
  return DonationType?.oneTime;
}

function queryStringHasAny(
  values: DonateQueryParamName | DonateQueryParamName[]
) {
  if (typeof values === "string") {
    values = [values];
  }
  for (const val of values) {
    if (getQueryStringValue(val)) {
      return true;
    }
  }
  return false;
}

function getInitialAutoRenew() {
  const qsf = getQueryStringValue("f");
  return qsf === "a";
}

export default function CheckoutStepper(props: {
  isMobile: boolean;
  setShowLanding: (val: boolean) => void;
  freeTrial?: boolean;
  forcedGift?: boolean;
  email?: string;
  freeTrialFormCompleted?: boolean;
}) {
  const { user } = useAuth0();
  const stripe = useStripe() as Stripe;
  const elements = useElements() as StripeElements;
  const { t } = useTranslation();
  const errorMessages = useErrorMessages();
  const [activeStep, setActiveStep] = useState(0);
  const [donationType, setDonationType] = useState(DonationType.oneTime);
  const [donationAmount, setDonationAmount] = useState(
    parseInt(getQueryStringValue("a") as string) ||
      DEFAULT_DONATION_AMOUNTS[DonationType.oneTime]
  );
  const [donationAdditionalAmount, setDonationAdditionalAmount] = useState(0);
  const [donationAmountValid, setDonationAmountValid] = useState(true);
  const [giftInfo, setGiftInfo] = useState({
    isGift: !!parseInt(getQueryStringValue("gm") as string) || false,
    sendNow: true,
    valid: false,
  } as GiftInfo);
  const [shippingInfo, setShippingInfo] = useState({
    country: "US",
  } as ShippingInfo);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    signUp: !giftInfo.isGift,
  } as UserInfo);
  const [paymentMethod, setPaymentMethod] = useState(
    props.freeTrial ? PaymentMethod.freeTrial : PaymentMethod.card
  );
  const [gift, setGift] = useState(null as any);
  const [forcedGift] = useState(props?.forcedGift || false);
  const [gifts, setGifts] = useState([] as any[]);
  const [qGift] = useState(getQueryStringValue(DonateQueryParam.Gift) || "");
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(
    process.env.NODE_ENV === "production" ? undefined : "abc123"
  );
  const [coupon, setCoupon] = useState({
    applied: false,
    value: getQueryStringValue(DonateQueryParam.Coupon),
    error: false,
  } as any);
  const [plaidToken, setPlaidToken] = useState(null as any);
  const [paymentsValid, setPaymentsValid] = useState(false);
  const [error, setError] = useState<string | JSX.Element>("");
  const [touched, setTouched] = useState<{ [k: number]: boolean }>({ 0: true });
  const [autoRenew, setAutoRenew] = useState(getInitialAutoRenew());
  // Only set donation amount after the initial load
  const donationTypeInitialRef = useRef(false);
  const [giftCouponConflictOpen, setGiftCouponConflictOpen] = useState(false);
  const [giftConflict, setGiftConflict] = useState<any>(null);
  const [couponUrl, setCouponUrl] = useState("");
  const [couponCopyBtnText, setCouponCopyBtnText] = useState("Copy");

  const isAdmin = useCallback(() => {
    if (!user) return false;
    const roles = user["https://jazzgroove.org/roles"] as string[];
    if (!roles) return false;
    return roles.includes("Administrator");
  }, [user]);

  useEffect(() => {
    const initDonationType = async function () {
      if (
        !queryStringHasAny([
          DonateQueryParam.Monthly,
          DonateQueryParam.Frequency,
          DonateQueryParam.GiftSelected,
        ])
      ) {
        const entry =
          await ContentfulClient.getEntry<ContentfulInitialDonationType>(
            CONTENTFUL_INITIAL_DONATION_TYPE_ID
          );
        setDonationType(
          entry.fields.donationType === "monthly"
            ? DonationType.monthly
            : DonationType.oneTime
        );
      } else if (forcedGift) {
        setDonationType(DonationType.oneTime);
      } else {
        setDonationType(getStartingDonationType());
      }
    };

    initDonationType().catch(console.error);

    return () => {};
  }, [forcedGift]);

  useEffect(() => {
    if (donationTypeInitialRef.current) {
      if (!getQueryStringValue("a")) {
        // Do not overwrite if there is an amount in the query string.
        setDonationAmount(DEFAULT_DONATION_AMOUNTS[donationType]);
      }
      if (donationType === DonationType.monthly) {
        setGiftInfo((giftInfo) =>
          giftInfo?.isGift ? { ...giftInfo, isGift: false } : giftInfo
        );
      }
    }
    donationTypeInitialRef.current = true;
  }, [donationType]);

  useEffect(() => {
    if (
      (donationAmount < DEFAULT_DONATION_AMOUNTS[DonationType.oneTime] &&
        donationType === DonationType.oneTime) ||
      donationType === DonationType.monthly
    ) {
      setAutoRenew(false);
    }
  }, [donationAmount, donationType]);

  useEffect(() => {
    if (donationAdditionalAmount > 0) {
      setDonationAdditionalAmount(
        parseFloat((donationAmount * 0.025).toFixed(0))
      );
    }
  }, [donationAmount, donationAdditionalAmount]);

  useEffect(() => {
    if (isAdmin()) {
      const params = new URLSearchParams();
      params.set("f", donationType);
      if (autoRenew) {
        params.set("m", "1");
      }
      if (giftInfo.isGift) {
        params.set("gm", "1");
      }
      if (giftInfo.isGift && gift) {
        params.set("g", gift.id);
      }
      if (coupon.value) {
        params.set("c", coupon.value);
      }
      params.set("a", donationAmount.toString());
      setCouponUrl(
        `${window.location.origin}${
          window.location.pathname
        }?${params.toString()}`
      );
    }
  }, [
    autoRenew,
    coupon,
    donationAmount,
    donationType,
    gift,
    giftInfo,
    isAdmin,
    user,
  ]);

  useEffect(() => {
    Axios.post(
      process.env.REACT_APP_API_ENDPOINT +
        `/donate/gifts${
          process.env.REACT_APP_NODE_ENV === "dev" ? "-dev" : ""
        }`,
      {
        planId: "*",
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY as string,
        },
      }
    )
      .then((response) => {
        setGifts(response.data);
        if (qGift) {
          response.data.forEach((g) => {
            if (g.id === qGift) {
              setGift(g);
            } else if (g.meta_data.variants) {
              g.meta_data.variants.forEach((v) => {
                if (v.id === qGift) {
                  setGift({ ...g, size: v });
                }
              });
            }
          });
        }
      })
      .catch(() => {
        console.error("Error fetching gifts");
      });
  }, [qGift]);

  const selectGift = useCallback(
    (g) => {
      const monthly = donationType === DonationType.monthly;
      if (
        !coupon.applied ||
        (coupon.applied &&
          g &&
          (g.id === "" || (g.meta_data && g.meta_data.couponAllowed)))
      ) {
        if (
          g &&
          g.meta_data &&
          g.meta_data.pricing &&
          g.meta_data.pricing[monthly ? "monthly" : "oneTime"] &&
          g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum >
            donationAmount
        ) {
          setDonationAmount(
            g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum
          );
        }
        setGift(g);
      } else {
        setGiftConflict(g);
        setGiftCouponConflictOpen(true);
      }
    },
    [setDonationAmount, setGift, donationType, donationAmount, coupon]
  );

  const onToggleGift = useCallback(
    (checked: Boolean) => {
      setUserInfo({ ...userInfo, signUp: !!checked });
    },
    [userInfo, setUserInfo]
  );
  const setFallbackGift = (g) => {
    setGift({
      ...defaultGift,
      fallbackGift: g,
    });
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submitDonation();
    } else {
      setActiveStep((prevActiveStep) => {
        const nextStep = prevActiveStep + 1;
        setTouched({ ...touched, [prevActiveStep]: true, [nextStep]: true });
        return nextStep;
      });
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.setShowLanding(true);
    } else {
      setActiveStep((prevActiveStep) =>
        prevActiveStep > 0 ? prevActiveStep - 1 : 0
      );
    }
  };

  const canBack = () => {
    return activeStep > 0;
  };

  const canSubmit = () => {
    return !steps.filter((x) => !x.valid()).length;
  };

  const getAddress = () => {
    return {
      fname: shippingInfo.firstName,
      lname: shippingInfo.lastName,
      addr1: shippingInfo.address1,
      addr2: shippingInfo.address2,
      city: shippingInfo.city,
      state: shippingInfo.state,
      zip: shippingInfo.zip,
      country: shippingInfo.country,
    };
  };

  const getRecipient = () => {
    const recipient = {
      first_name: "",
      last_name: "",
      email: "",
      note: "",
      sendNow: true,
      date: undefined as any,
    };
    if (giftInfo.isGift) {
      recipient.first_name = giftInfo.firstName;
      recipient.last_name = giftInfo.lastName;
      recipient.email = giftInfo.email;
      recipient.note = giftInfo.note;
      recipient.sendNow = giftInfo.sendNow;
      recipient.date = giftInfo.sendDate;
      if (!recipient.date.isSameOrAfter(moment().add(10, "m"))) {
        recipient.sendNow = true;
      }
    }
    return recipient;
  };

  const { language } = getI18n();
  var isJapan = false;
  var userLang = language;

  if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
    isJapan = true;

  if (!coupon.value && isJapan) {
    setCoupon({ applied: false, value: "jp30" });
  }

  const getCustomer = () => {
    return {
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
      email: userInfo.email,
      auth0_id: userInfo.auth0Id,
      meta_data: { language },
    };
  };

  const getGiftId = () => {
    if (gift) {
      return gift.size ? gift.size.id : gift.id;
    }
    return "";
  };

  const getShippingFee = () => {
    const fee =
      paymentMethod === PaymentMethod.card &&
      getGiftId() &&
      shippingInfo.country !== "US"
        ? NON_US_SHIPPING_FEE
        : 0;
    return fee;
  };

  const getCouponAmount = () => {
    let couponAmount = 0;
    if (coupon.applied) {
      if (coupon.discountType === "fixed_amount") {
        couponAmount = coupon.discountAmount;
      } else if (coupon.discountType === "percentage") {
        couponAmount = Math.round(
          (coupon.discountAmount *
            (donationAmount + donationAdditionalAmount)) /
            100
        );
      }
    }
    return couponAmount;
  };

  const submitDonation = async () => {
    setLoading(true);
    try {
      let couponAmount = getCouponAmount();
      /* Payment amount is NOT the same as subscription amount, and varies by payment method.
       *  For card payments, we need to include shipping fees in the payment amount when requesting
       *  a payment intent from Stripe.  For PayPal and ACH, we do NOT, because the back end takes
       *  care of setting the amounts.  In addition, add-ons such as gifts and coupon amounts are all
       *  handled in the back end for all three methods by adding them as part of the subscription
       *  creation process.
       */
      let paymentAmount =
        donationAmount + donationAdditionalAmount - couponAmount;
      switch (paymentMethod) {
        case PaymentMethod.card:
          if (!captcha) {
            throw new Error("Please complete the captcha.");
          }
          const shippingAmount = getShippingFee();
          paymentAmount += shippingAmount;
          let paymentIntent;
          const x = await stripe.createPaymentMethod({
            billing_details: {
              name: userInfo.firstName + " " + userInfo.lastName,
              email: userInfo.email,
            },
            type: "card",
            card: elements.getElement(
              CardNumberElement
            ) as StripeCardNumberElement,
          });

          const paymentIntentResponse = await Axios.get(
            process.env.REACT_APP_API_ENDPOINT +
              `/donate/secret?payment_method=${
                x?.paymentMethod?.id
              }&amount=${paymentAmount}&dev=${
                process.env.REACT_APP_NODE_ENV === "dev"
              }`
          );
          if (paymentIntentResponse.data.statusCode !== 200) {
            // record paymentIntentResponse.body to sentry
            throw new Error(paymentIntentResponse.data.message);
          }
          const paymentIntentResult = await stripe.retrievePaymentIntent(
            paymentIntentResponse.data.body.secret
          );
          if (
            paymentIntentResult?.paymentIntent?.status ===
              "requires_confirmation" ||
            paymentIntentResult?.paymentIntent?.status === "requires_action"
          ) {
            const result = await stripe.confirmCardPayment(
              paymentIntentResponse.data.body.secret
            );
            if (result.error) throw new Error(result.error.message);
            paymentIntent = result.paymentIntent;
          } else {
            paymentIntent = paymentIntentResult.paymentIntent;
          }

          const cardResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT + "/v2",
            {
              captcha: captcha,
              customer: getCustomer(),
              shippingAddress: getAddress(),
              shippingAmount: shippingAmount,
              couponAmount: couponAmount,
              donationAmount: donationAmount,
              donationAdditionalAmount: donationAdditionalAmount,
              amount: donationAmount + donationAdditionalAmount,
              paymentMethod: {
                type: "card",
                paymentIntent: paymentIntent,
              },
              autoRenew: autoRenew,
              monthly: donationType === DonationType.monthly,
              plan: getDonationPlanCode(donationAmount, donationType),
              coupon: coupon.applied ? coupon.value : undefined,
              shipping: gift ? gift.is_shippable : "",
              gift: getGiftId(),
              isGift: giftInfo.isGift,
              giftRecipient: getRecipient(),
              dev: process.env.REACT_APP_NODE_ENV === "dev",
              password: userInfo.signUp ? userInfo.password : undefined,
            }
          );

          History.push("/thank-you", {
            ...cardResponse.data,
            signUp: userInfo.signUp,
            freeTrial: props.freeTrial,
          });
          break;
        case PaymentMethod.payPal:
          const paypalResponse = await Axios.post<
            any,
            AxiosResponse,
            PaypalProps
          >(process.env.REACT_APP_API_ENDPOINT + "/checkout/generate-paypal", {
            amount: paymentAmount,
            shippingAmount: getShippingFee(),
            donationAmount: donationAmount,
            donationAdditionalAmount: donationAdditionalAmount,
            coupon: coupon.applied ? coupon.value : "",
            gift: getGiftId(),
            autoRenew: autoRenew,
            plan: getDonationPlanCode(donationAmount, donationType) as PlanCode,
            customer: getCustomer(),
            donationType: donationType,
            monthly: donationType === DonationType.monthly,
            shipping: gift ? gift.is_shippable : "",
            isGift: giftInfo.isGift,
            intl: shippingInfo.country !== "US",
            giftRecipient: getRecipient(),
            dev: process.env.REACT_APP_NODE_ENV === "dev",
            password: userInfo.signUp ? userInfo.password : undefined,
          });

          window.open(paypalResponse.data.url, "_self");
          break;
        case PaymentMethod.ach:
          if (plaidToken.error) throw new Error(plaidToken.error.message);
          const achResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT + "/v2",
            {
              customer: getCustomer(),
              shippingAddress: getAddress(),
              shippingAmount: getShippingFee(),
              amount: paymentAmount,
              donationAmount: donationAmount,
              donationAdditionalAmount: donationAdditionalAmount,
              paymentMethod: {
                type: "direct_debit",
                token: plaidToken.token,
                accountId: plaidToken.accountId,
              },
              autoRenew: autoRenew,
              monthly: donationType === DonationType.monthly,
              plan: getDonationPlanCode(donationAmount, donationType),
              coupon: coupon.applied ? coupon.value : undefined,
              shipping: gift ? gift.is_shippable : "",
              gift: getGiftId(),
              isGift: giftInfo.isGift,
              giftRecipient: getRecipient(),
              dev: process.env.REACT_APP_NODE_ENV === "dev",
              password: userInfo.signUp ? userInfo.password : undefined,
            }
          );

          History.push("/thank-you", {
            ...achResponse.data,
            signUp: userInfo.signUp,
            freeTrial: props.freeTrial,
          });
          break;
        case PaymentMethod.freeTrial:
          if (!captcha) {
            throw new Error("Please complete the captcha.");
          }

          const ftResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT + "/v2",
            {
              captcha: captcha,
              customer: getCustomer(),
              shippingAddress: getAddress(),
              amount: 1000,
              paymentMethod: {
                type: "freeTrial",
              },
              autoRenew: false,
              monthly: true,
              plan: getDonationPlanCode(donationAmount, donationType),
              coupon: coupon.applied ? coupon.value : undefined,
              shipping: "",
              gift: "",
              isGift: false,
              giftRecipient: getRecipient(),
              dev: process.env.REACT_APP_NODE_ENV === "dev",
              password: userInfo.signUp ? userInfo.password : undefined,
            }
          );
          History.push("/thank-you", {
            ...ftResponse.data,
            signUp: userInfo.signUp,
            freeTrial: props.freeTrial,
          });
          break;
        default:
          setError("Invalid payment method");
      }
    } catch (e: any) {
      setLoading(false);
      if (e.message === "token is undefined") {
        setError("Please enter valid payment information");
      } else {
        if (
          e.response &&
          e.response.data &&
          e.response.data.code &&
          errorMessages[e.response.data.code as ErrorCode]
        ) {
          setError(errorMessages[e.response.data.code as ErrorCode]);
        } else {
          setError(
            e.response && e.response.data
              ? e.response.data.message
              : e.message
              ? e.message
              : e
          );
        }
      }
    }
  };

  const renderDonationTypeStep = () => {
    return (
      <>
        <DonationTypeStep
          donationType={donationType}
          forcedGift={forcedGift}
          setDonationType={setDonationType}
        />
        {donationType !== DonationType.monthly && (
          <GiftRecipientStep
            onToggle={onToggleGift}
            giftInfo={giftInfo}
            forcedGift={forcedGift}
            setGiftInfo={setGiftInfo}
          />
        )}
      </>
    );
  };

  const renderDonationAmountStep = () => {
    return (
      <DonationAmountStep
        donationAmount={donationAmount}
        setDonationAmount={setDonationAmount}
        donationType={donationType}
        isGift={giftInfo.isGift}
        setIsValid={setDonationAmountValid}
        gift={gift}
        resetGift={() => selectGift(null)}
      ></DonationAmountStep>
    );
  };

  const renderThankYouGiftStep = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GiftStep
            gifts={gifts}
            gift={gift}
            setGift={selectGift}
            donationAmount={donationAmount}
            donationType={donationType}
          ></GiftStep>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: gift && gift.is_shippable ? "flex" : "none" }}
        >
          <ShippingStep
            shippingInfo={shippingInfo}
            setShippingInfo={setShippingInfo}
          ></ShippingStep>
        </Grid>
      </Grid>
    );
  };

  const renderUserInfoStep = () => {
    return (
      <UserInfoStep
        userInfo={userInfo}
        externalEmail={props?.email}
        giftInfo={giftInfo}
        setUserInfo={setUserInfo}
        forcedGift={forcedGift}
        requireSignUp={props.freeTrial}
        freeTrialFormCompleted={props?.freeTrialFormCompleted}
      ></UserInfoStep>
    );
  };

  const renderPaymentStep = () => {
    return (
      <PaymentStep
        stripe={stripe}
        elements={elements}
        paymentMethod={paymentMethod}
        forcedGift={forcedGift}
        donationAmount={donationAmount}
        setPaymentMethod={setPaymentMethod}
        setCaptcha={setCaptcha}
        coupon={coupon}
        setCoupon={setCoupon}
        isGift={giftInfo.isGift}
        gift={gift}
        loading={loading}
        plaidToken={plaidToken}
        setPlaidToken={setPlaidToken}
        setIsValid={setPaymentsValid}
        setFallbackGift={setFallbackGift}
        donationPlan={
          getDonationPlanCode(donationAmount, donationType) as string
        }
        donationAdditionalAmount={donationAdditionalAmount}
        setDonationAdditionalAmount={setDonationAdditionalAmount}
        autoRenew={autoRenew}
        setAutoRenew={setAutoRenew}
        donationType={donationType}
        freeTrial={props.freeTrial}
      ></PaymentStep>
    );
  };

  const getStepLabel = (step) => {
    return (
      <Box sx={{ pl: 1 }}>
        <Typography
          variant="overline"
          component="div"
          sx={{
            color: "text.secondary",
            lineHeight: 1.1,
          }}
        >
          {t("donationPage.step")} {step.key}
        </Typography>
        <Typography variant="h5" component="div">
          {typeof step.label === "string" ? (
            <b>{step.label}</b>
          ) : (
            <>{step.label()}</>
          )}
        </Typography>
      </Box>
    );
  };

  const renderDonationSummary = (
    borderless = false,
    transparent = false,
    disableDonateButton = false
  ) => {
    return (
      <DonationSummary
        donationType={donationType}
        forcedGift={forcedGift}
        donationAmount={donationAmount}
        gift={gift}
        donationAdditionalAmount={donationAdditionalAmount}
        setDonationAmount={setDonationAmount}
        borderless={borderless}
        transparent={transparent}
        shippingFee={getShippingFee()}
        coupon={coupon}
        autoRenew={autoRenew}
        freeTrial={props.freeTrial}
        action={
          !disableDonateButton && (
            <>
              <StepNav
                fullWidth
                donateOnly
                showHint
                freeTrial={props.freeTrial}
                sx={{ mt: 1 }}
              />
              {error && (
                <Alert variant="standard" severity="error" sx={{ mt: 1 }}>
                  <Typography variant="caption">{error}</Typography>
                </Alert>
              )}
            </>
          )
        }
      ></DonationSummary>
    );
  };

  const StepNav = (
    props: StackProps &
      Pick<
        CheckoutStepperNavProps,
        "fullWidth" | "hidePrevious" | "donateOnly" | "showHint" | "freeTrial"
      >
  ) => {
    return (
      <CheckoutStepperNav
        steps={steps}
        emailExists={userInfo.emailExists}
        isStepValid={stepValid}
        forcedGift={forcedGift}
        canGoBack={() => canBack()}
        canSubmit={canSubmit}
        onNext={!props.donateOnly ? handleNext : submitDonation}
        onBack={handleBack}
        loading={loading}
        activeStep={activeStep}
        {...props}
      />
    );
  };

  const normalSteps: CheckoutStep[] = [
    {
      key: 1,
      label: forcedGift
        ? "Enter recipient information"
        : t("donationPage.donationType"),
      summary: () =>
        `${
          donationType === DonationType.monthly
            ? t("donationPage.monthly")
            : t("donationPage.oneTime")
        }${giftInfo.isGift ? " Gift" : ""}`,
      render: renderDonationTypeStep,
      valid: () => !giftInfo.isGift || giftInfo.valid,
      rightComponent: RightComponent.Info,
      transitionProps: {},
      upsell: false,
    },
    {
      key: 2,
      label: t("donationPage.donationAmount"),
      summary: () => formatCurrency(donationAmount / 100),
      render: renderDonationAmountStep,
      valid: () => donationAmountValid,
      rightComponent: RightComponent.Info,
      transitionProps: {},
      upsell: false,
    },
    ...(!isJapan
      ? [
          {
            key: 3,
            label: () => (
              <>
                <b>Thank you gift</b>&nbsp;
                <Typography
                  component="span"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.8em",
                    fontWeight: "regular",
                    fontStyle: "italic",
                    lineHeight: "inherit",
                  }}
                >
                  (optional)
                </Typography>
                <Tooltip
                  title={
                    "You may optionally choose to receive a thank you gift, but your donation will no longer be 100% tax deductible. Gifts require a minimum donation amount and include FREE shipping within the US."
                  }
                >
                  <Box
                    sx={{
                      display: "inline-block",
                      height: "1.1em",
                      ml: 1,
                      color: "primary.main",
                    }}
                  >
                    <JGIcon
                      is={IoInformationCircleOutline}
                      fontSize="small"
                      sx={{ height: "inherit" }}
                    />
                  </Box>
                </Tooltip>
              </>
            ),
            summary: () => `${gift != null ? gift.name : "No gift selected"}`,
            render: renderThankYouGiftStep,
            valid: () => !gift || !gift.is_shippable || shippingInfo.valid,
            rightComponent: RightComponent.Info,
            transitionProps: {},
            upsell: false,
          },
        ]
      : []),
    {
      key: 4,
      label: t("donationPage.addYourDetails"),
      summary: () => `${userInfo.firstName || ""} ${userInfo.lastName || ""}`,
      render: renderUserInfoStep,
      valid: () => !!(userInfo && userInfo.valid),
      rightComponent: RightComponent.Summary,
      transitionProps: {},
      upsell: true,
    },
    ...(!userInfo.emailExists
      ? [
          {
            key: 5,
            label: t("donationPage.paymentMethod"),
            summary: () =>
              paymentMethod === PaymentMethod.card
                ? t("donationPage.creditCard")
                : paymentMethod === PaymentMethod.payPal
                ? t("donationPage.paypal")
                : t("donationPage.checking"),
            render: renderPaymentStep,
            valid: () => paymentsValid,
            rightComponent: RightComponent.Summary,
            transitionProps: { unmountOnExit: false }, // Don't unmount so we dont lose payment info
            upsell: true,
          },
        ]
      : []),
    ...(props.isMobile
      ? [
          {
            key: 6,
            label: t("donationPage.summary"),
            summary: () => "",
            render: () => (
              <>
                {renderDonationSummary(true, true, true)}
                {error && (
                  <Alert severity="error" sx={{ mt: 3 }}>
                    {error}
                  </Alert>
                )}
              </>
            ),
            valid: () => true,
            rightComponent: RightComponent.Summary,
            transitionProps: {},
            upsell: true,
          },
        ]
      : []),
  ];

  const freeTrialSteps: CheckoutStep[] = [
    {
      key: 1,
      label: "Add your details",
      summary: () => `${userInfo.firstName || ""} ${userInfo.lastName || ""}`,
      render: renderUserInfoStep,
      valid: () => !!(userInfo && userInfo.valid),
      rightComponent: RightComponent.Summary,
      transitionProps: {},
      upsell: false,
    },
    {
      key: 2,
      label: 'Check "I\'m not a robot" and click "Unlock Free Membership"',
      summary: () => "",
      render: renderPaymentStep,
      valid: () => paymentsValid,
      rightComponent: RightComponent.Summary,
      transitionProps: { unmountOnExit: false }, // Don't unmount so we dont lose payment info
      upsell: false,
    },
  ];

  const steps = props.freeTrial ? freeTrialSteps : normalSteps;

  const stepValid = () => {
    return steps[activeStep].valid();
  };

  const stepperIconSize = 52;

  const infoStyles = {
    position: "sticky",
    top: "calc(var(--jg-header-height) + 24px)",
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {props.isMobile ? (
          steps.map((step, index) => {
            return (
              <Box
                key={index}
                sx={{ display: index === activeStep ? "initial" : "none" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Paper
                    square
                    elevation={0}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 80,
                    }}
                  >
                    {!props.freeTrial ||
                      (props.freeTrial && activeStep > 0 && (
                        <Box sx={{ flexShrink: 1, p: 2 }} onClick={handleBack}>
                          <IoArrowBack size={"25px"} />
                        </Box>
                      ))}
                    <Box
                      sx={{
                        flexGrow: 1,
                        pl: props.freeTrial && activeStep === 0 ? 2 : 0,
                      }}
                    >
                      <Typography
                        sx={{ color: "text.secondary", lineHeight: 1.2 }}
                        component="p"
                        variant="overline"
                      >
                        <b>STEP {activeStep + 1}</b> / {steps.length}
                      </Typography>
                      <Typography variant="h4">
                        {typeof step.label === "string" ? (
                          <b>{step.label}</b>
                        ) : (
                          <>{(step.label as () => JSX.Element)()}</>
                        )}
                      </Typography>
                    </Box>
                  </Paper>
                </Box>

                <Grid container spacing={1} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    {step.render()}
                  </Grid>
                  <Grid item xs={12}>
                    <StepNav fullWidth freeTrial={props.freeTrial} />
                  </Grid>
                </Grid>
              </Box>
            );
          })
        ) : (
          <Grid container={!props.freeTrial}>
            <Grid item={!props.freeTrial} xs={8}>
              <Box sx={{ p: 2 }}>
                <Stepper
                  nonLinear
                  orientation="vertical"
                  activeStep={-1}
                  connector={
                    <StepConnector
                      style={{ marginLeft: `${stepperIconSize / 2}px` }}
                    />
                  }
                  sx={{
                    [`& .${stepContentClasses.root}, & .${stepConnectorClasses.line}`]:
                      {
                        borderWidth: 4,
                        borderColor: "border.elevation",
                      },
                  }}
                >
                  {steps.map((step, index) => {
                    return (
                      <Step
                        key={step.key}
                        completed={step.valid()}
                        expanded={true}
                      >
                        <StepLabel
                          StepIconProps={{
                            completed: false,
                            sx: (theme) => ({
                              width: stepperIconSize,
                              height: stepperIconSize,
                              fontWeight: theme.typography.fontWeightBold,
                              border: `${theme.shape.borderWidths[2]}px solid ${theme.palette.border.elevation}`,
                              borderRadius: "50%",
                              fill: theme.palette.background.paper,
                            }),
                          }}
                          sx={{
                            [`& .${stepLabelClasses.label}`]: {
                              color: "text.primary",
                            },
                          }}
                        >
                          {getStepLabel(step)}
                          <Typography variant="caption" sx={{ pl: 1 }}>
                            {step.summary()}
                          </Typography>
                        </StepLabel>
                        <StepContent
                          style={{ marginLeft: `${stepperIconSize / 2}px` }}
                          sx={{ py: 2 }}
                          TransitionProps={{
                            ...step.transitionProps,
                            style: { marginLeft: `${stepperIconSize / 2}px` },
                          }}
                        >
                          {step.render()}
                          {index === steps.length - 1 && (
                            <StepNav
                              fullWidth
                              donateOnly
                              freeTrial={props.freeTrial}
                            />
                          )}
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
                <Box sx={{ pl: `${stepperIconSize + 24}px` }}>
                  {error && <Alert severity="error">{error}</Alert>}
                  {isAdmin() && (
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(couponUrl);
                        setCouponCopyBtnText("Copied");
                        setTimeout(() => setCouponCopyBtnText("Copy"), 3000);
                      }}
                    >
                      {couponCopyBtnText} URL
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
            {!props.freeTrial && (
              <Grid item xs={4}>
                <Box sx={infoStyles}>
                  <>{renderDonationSummary()}</>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <ConfirmationDialog
        open={giftCouponConflictOpen}
        onClose={(confirmed) => {
          setGiftCouponConflictOpen(false);
          if (confirmed) {
            const monthly = donationType === DonationType.monthly;
            const g = giftConflict;
            setCoupon({ applied: false, value: "" });
            if (
              g &&
              g.meta_data &&
              g.meta_data.pricing &&
              g.meta_data.pricing[monthly ? "monthly" : "oneTime"] &&
              g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum >
                donationAmount
            ) {
              setDonationAmount(
                g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum
              );
            }
            setGift(g);
          }
        }}
        cancelText={"No"}
        confirmText={"Yes"}
      >
        Gifts are not eligible when using coupons. Would you like to remove your
        coupon and select this gift?
      </ConfirmationDialog>
    </>
  );
}
