import { PaletteColor, darken } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";

export const stationColorMap: Record<StationId, string> = {
  mix1: "#8490FF",
  mix2: "#3DBEC5",
  dreams: "#41A4FC",
  gems: "#DBA94F", // "#DB4F4F",
  smooth: "#B96FBB",
  christmas: "#DB4F4F",
  demo: "#8490FF",
};

export const stationPaletteMap = Object.entries(stationColorMap).reduce(
  (accum, [stationId, color]) => {
    accum[stationId] = {
      main: color,
      dark: darken(color, 0.3),
      darker: darken(color, 0.5),
      contrastText: "#FFFFFF",
    } as PaletteColor;
    return accum;
  },
  {} as Record<StationId, PaletteColor>
);

export const stationGreyMap: Readonly<
  Partial<Record<StationId, ColorPartial>>
> = {
  mix1: {
    // TODO: The rest of the scale
    500: "#4E4E50",
    600: "#444446",
    700: "#39393B",
    800: "#2F2F31",
    900: "#262627",
  },
};
