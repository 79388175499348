import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import PlanFeatureIcon from "./PlanFeatureIcon";
import { isIncludedInPlan } from "Utils/Components/Plan";
import { PLAN_FEATURES, PlanFeature, PlanTierKey } from "Utils/Constants";
import PlanFeatureFootnote from "./PlanFeatureFootnote";
import "../../../../i18n/config";
import { useTranslation } from "react-i18next";

export type PlanFeatureItemSize = "small" | "medium";
interface PlanFeatureItemProps {
  feature: PlanFeature;
  tier: PlanTierKey;
  size?: PlanFeatureItemSize;
  giftEnabled: boolean;
}

const PlanFeatureItem = (props: PlanFeatureItemProps) => {
  const { tier, feature, size, giftEnabled } = props;

  const { t } = useTranslation();

  const index = PLAN_FEATURES.indexOf(feature);

  return (
    <ListItem key={index} disableGutters sx={{ py: 0 }}>
      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
        <PlanFeatureIcon
          feature={feature}
          tier={tier}
          star={index === 0}
          giftEnabled={giftEnabled}
          sx={{
            fontSize: size === "small" ? "0.875rem" : undefined,
          }}
        />
      </ListItemIcon>

      <ListItemText
        primaryTypographyProps={{
          variant: size === "small" ? "body2" : "body1",
          color: isIncludedInPlan(feature, tier, giftEnabled)
            ? "text.main"
            : "text.disabled",
        }}
        sx={{
          my: size === "small" ? 0.25 : undefined,
        }}
      >
        {t("planFeatures." + feature.featureKey)}

        <PlanFeatureFootnote feature={feature} />
      </ListItemText>
    </ListItem>
  );
};

export default PlanFeatureItem;
