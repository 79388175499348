import { Grid } from "@mui/material";
import { useEffect } from "react";
import { DonationType } from "Utils/Constants/Donate";
import SelectBubble from "../SelectBubble";
import "../../../../i18n/config";
import { useTranslation } from "react-i18next";

const DonationTypeStep = (props: {
  setDonationType: (donationType: DonationType) => void;
  donationType: DonationType;
  forcedGift?: boolean;
}) => {
  const { setDonationType, donationType, forcedGift } = props;
  const { setDonationType: propsSetDonationType } = props;
  const { t } = useTranslation();

  useEffect(() => {
    propsSetDonationType(donationType);
  }, [donationType, propsSetDonationType]);

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        {!forcedGift && (
          <>
            <Grid item xs={6} md={4}>
              <SelectBubble
                showCheck
                selected={donationType === DonationType.monthly}
                label={t("donationPage.monthly")}
                onClick={() => setDonationType(DonationType.monthly)}
              ></SelectBubble>
            </Grid>

            <Grid item xs={6} md={4}>
              <SelectBubble
                showCheck
                selected={donationType === DonationType.oneTime}
                label={t("donationPage.oneTime")}
                onClick={() => setDonationType(DonationType.oneTime)}
              ></SelectBubble>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DonationTypeStep;
