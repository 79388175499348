import React, { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import ReactGA from "react-ga";
import { buildDonatePath, BuildDonatePathProps } from "Utils/Checkout";
import { PlanExpirationDialogProps } from "./PlanExpirationDialog";
import "../../i18n/config";
import { useTranslation } from "react-i18next";

export type DonateButtonProps = ButtonProps &
  Partial<LinkProps> & {
    queryParams?: BuildDonatePathProps;
    isFreeTrial?: boolean;
    expirationDialog?: PlanExpirationDialogProps;
  };

const DonateButton = forwardRef<any, DonateButtonProps>((props, ref) => {
  const {
    children,
    queryParams,
    expirationDialog,
    isFreeTrial = false,
    ...rest
  } = props;

  const { t } = useTranslation();

  const openExpirationDialog = () => {
    if (!expirationDialog) return;

    ReactGA.event({
      category: "button",
      action: "expiring",
      days: expirationDialog.subscription.termEnd.daysUntil,
    } as any);

    expirationDialog.setOpen(true);
  };

  return expirationDialog?.subscription ? (
    <Button ref={ref} color="primary" onClick={openExpirationDialog} {...rest}>
      {children || "Renew my plan"}
    </Button>
  ) : (
    <Button
      ref={ref}
      color="primary"
      component={Link}
      to={buildDonatePath(queryParams)}
      target={buildDonatePath(queryParams)}
      {...rest}
    >
      {children || (isFreeTrial ? "Become a member" : t("donationPage.title"))}
    </Button>
  );
});

export default DonateButton;
