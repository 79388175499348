import { useEffect, useRef, useState, useCallback } from "react";
import CheckoutStepper from "./Components/Checkout/CheckoutStepper";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  Alert,
  useScrollTrigger,
  Fade,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useAsync } from "react-use";
import ImagesCover from "Assets/free-trial/ALBUMCOVERS.png";
import Ellipse from "Assets/free-trial/Ellipse.png";
import EllipseBottom from "Assets/free-trial/EllipseBottom.png";
import GrainBg from "Assets/free-trial/Grain-bg.png";
import CardsBg from "Assets/free-trial/cards_bg.png";
import TestimonialsBgMobile from "Assets/free-trial/testimonials_bg_mobile.png";
import TestimonialsBg from "Assets/free-trial/testimonials_bg.png";
import Logo from "Pages/Components/Logo";
import ContentfulClient from "Utils/ContentfulClient";
import {
  PremiumQualityIcon,
  PremiumSilenceIcon,
  PremiumSkipIcon,
} from "./Components/Icons";
import Title from "Pages/Components/Title";
import { FreeTrialDocDto } from "../../types/Donate";
import UnlimitedAccessIcon from "./Components/Icons/UnlimitedAccessIcon";
import styles from "./Components/FreeTrial/FreeTrial.module.css";
import ChrisBotti from "Assets/free-trial/photos/chris-botti.png";
import BradMehldau from "Assets/free-trial/photos/Brad-Mehldau.png";
import ChetBaker from "Assets/free-trial/photos/Chet-Baker-Mobile.png";
import DianaKrall from "Assets/free-trial/photos/Diana-Krall.png";
import LouisArmstrong from "Assets/free-trial/photos/Louis-Armstrong-Pyramids-Mobile.png";
import MilesDavis from "Assets/free-trial/photos/Miles.png";
import SonnyRollins from "Assets/free-trial/photos/Sonny-Rollins.png";
import WyntonMarsalis from "Assets/free-trial/photos/Wynton-Marsalis-Mobile.png";
import BrandeeYounger from "Assets/free-trial/photos/Brandee-Younger.png";
import DillyGillespie from "Assets/free-trial/photos/Dizzy-Gillespie.png";
import DukeEllington from "Assets/free-trial/photos/Duke-Ellington.png";
import EllaBest from "Assets/free-trial/photos/Ella-Best.png";
import RayCharles from "Assets/free-trial/photos/Ray-Charles.png";
import HerbieHancock from "Assets/free-trial/photos/Herbie-Hancock.png";
import pressPlayCircular from "Assets/free-trial/press_play.svg";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Player from "./Components/Player/Player";
import Axios from "axios";
import Spinner from "./Components/Spinner";
import UserApi from "Api/UserApi";
import { IoMdPhonePortrait } from "react-icons/io";
import { FaTabletAlt } from "react-icons/fa";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { BsFillSpeakerFill } from "react-icons/bs";
import { SiSonos } from "react-icons/si";
import { SiAmazonalexa } from "react-icons/si";
import { SiTunein } from "react-icons/si";
import Heos from "Assets/heos.png";
import { useAppContext } from "Context";

const legends = [
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Ella Fitzgerald", image: EllaBest },
  { name: "Dizzy Gillespie", image: DillyGillespie },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Ray Charles", image: RayCharles },
  { name: "Duke Ellington", image: DukeEllington },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Herbie Hancock", image: HerbieHancock },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Brandee Younger", image: BrandeeYounger },
];

const testimonials = [
  {
    name: "Doug",
    comment:
      "My wife and I love The Jazz Groove! We listen to it everytime we are at home; the playlists are absolutely first-rate, don't change a thing!",
  },
  {
    name: "Jane",
    comment:
      "The Jazz Groove is the best! I love laid-back Jazz but had never found a station that consistently played it... For something that provides me with so much enjoyment, I happily contribute.",
  },
  {
    name: "Bill & Joan",
    comment:
      "We just made our annual contribution to The Jazz Groove. The calming effect The Jazz Groove has on our pets is just amazing",
  },
  {
    name: "Bill",
    comment:
      "It is so relaxing and puts me in such a great headspace—I just enjoy listening on a daily basis",
  },
  {
    name: "Artie",
    comment:
      "I keep contributing to The Jazz Groove because the station just resonates with me. Your music helps me balance, mentally—you're just doing everything right. Keep up the good work",
  },
  {
    name: "Christopher",
    comment:
      "There’s no other station with music so carefully curated. When I come home in the evening, all I need to do is put it on, sit back and relax",
  },
  {
    name: "Daryl",
    comment:
      "With all the jazz stations out there, The Jazz Groove stands out with the selection of music and tone that we were looking for",
  },
  {
    name: "Bill",
    comment:
      "As a devoted and grateful listener, it’s easy to speak in superlatives when discussing The Jazz Groove. It's my daily companion, the music station I go to first, last and always",
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 6,
  },
  smalldesktop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Device(props: {
  icon?: React.ElementType;
  image?: string;
  title: string;
  body: string | JSX.Element;
  height?: string;
  size?: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      item
      xs={6}
      md={3}
      sx={{ px: 1, pt: isMobile ? 2 : 0, mt: isMobile ? 3 : 5 }}
    >
      {props?.image && (
        <img
          src={props.image}
          height={props?.height ? props.height : "50px"}
          alt="icon"
          style={{ marginTop: isMobile ? 3 : 25 }}
        />
      )}

      {props?.icon && (
        <props.icon size={props?.size ? props.size : 70} color="#ffffff" />
      )}
      {props?.title && (
        <Box style={{ marginTop: 4 }}>
          <Typography
            sx={{
              fontFamily: "futura-pt",
              fontWeight: 450,
              fontSize: "1.5em",
            }}
          >
            {props.title}
          </Typography>
        </Box>
      )}
      {props?.body && (
        <Typography
          sx={{
            fontFamily: "futura-pt",
            fontWeight: 400,
            fontSize: "1.5em",
            color: "#b0b0b0",
          }}
        >
          {props.body}
        </Typography>
      )}
    </Grid>
  );
}

function Perk(props: {
  icon: React.ElementType;
  title: string;
  body: string | JSX.Element;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const iconSize = isMobile ? 80 : 208;
  return (
    <Stack
      direction={isMobile ? "row" : "column"}
      spacing={2}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: iconSize }}>
        <props.icon
          style={{
            width: iconSize,
            height: iconSize,
            flexShrink: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          textAlign: isMobile ? "left" : "center",
          flex: "auto",
        }}
      >
        <Typography variant="h5" component="p" sx={{ pb: isMobile ? 0 : 1 }}>
          {props.title}
        </Typography>
        <Typography variant="body2" style={{ fontSize: "18px" }}>
          {props.body}
        </Typography>
      </Box>
    </Stack>
  );
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const FreeTrial = (props) => {
  const theme = useTheme();
  const { paused } = useAppContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTinyMobile = useMediaQuery("(max-width: 400px)");
  // console.log("🚀 ~ FreeTrial ~ isMobile:", isMobile);
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const isMediumDesktop = useMediaQuery("(max-width: 1370px)");
  // console.log("🚀 ~ FreeTrial ~ isMediumDesktop:", isMediumDesktop);
  // console.log("🚀 ~ FreeTrial ~ isSmallDesktop:", isSmallDesktop);
  const [showLanding, setShowLanding] = useState(true);
  console.log("🚀 ~ FreeTrial ~ showLanding:", showLanding);
  const [contentfulEntry, setContentfulEntry] = useState<null | string>(null);
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);
  const [freeTrialFormCompleted, setFreeTrialFormCompleted] = useState(false);
  const [coupon, setCoupon] = useState<null | string>(null);
  const [email, setEmail] = useState<undefined | string>(undefined);
  const [emailFocused, setEmailFocused] = useState(false);
  const [emailError, setEmailError] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<null | HTMLDivElement>(null);
  const checkoutRef = useRef<null | HTMLDivElement>(null);
  const emailRef = useRef<null | HTMLInputElement>(null);
  let sliderRef = useRef<Slider | null>(null);

  const hasScrolledDown = useScrollTrigger({
    disableHysteresis: true,
    threshold: 25,
  });

  const legendsItems: [JSX.Element?] = [];
  legends.forEach((legend) => {
    legendsItems.push(
      <div>
        <Box
          sx={{
            width: isMobile ? "180px" : "280px",
            height: isMobile ? "180px" : "280px",
            borderRadius: "18px",
            background: `url(${legend.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              width: "100%",
              textAlign: "center",
              zIndex: 5,
            }}
          >
            <Typography variant={"body1"}>{legend.name}</Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              width: isMobile ? "180px" : "280px",
              height: isMobile ? "70px" : "140px",
              borderRadius: "18px",
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)`,
              zIndex: 3,
            }}
          ></Box>
        </Box>
      </div>
    );
  });

  const testimonialsItems: [JSX.Element?] = [];
  testimonials.forEach((legend) => {
    testimonialsItems.push(
      <Grid
        container
        xs={12}
        sx={{
          minHeight: isTinyMobile
            ? "250px"
            : isMediumDesktop
            ? "327px"
            : "160px",
          borderRadius: "18px",
          background: `url(${
            isMediumDesktop ? TestimonialsBgMobile : TestimonialsBg
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: 5,
        }}
      >
        <Grid
          item
          sx={{
            zIndex: 5,
          }}
          xs={12}
        >
          <Box
            sx={{
              display: "block",
              textAlign: "center",
              padding: isTinyMobile
                ? "80px 15px 10px "
                : isMediumDesktop
                ? "120px 20px 10px"
                : "50px 50px 10px",
            }}
          >
            <Typography variant={"body1"}>{legend.comment}</Typography>
            <Typography variant={"body1"}>
              <b>{legend.name}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  });

  const { value: freeTrialDoc } = useAsync(async () => {
    if (contentfulEntry) {
      const entry = await ContentfulClient.getEntry<FreeTrialDocDto>(
        contentfulEntry
      );

      let options = {
        renderNode: {
          "embedded-asset-block": (node) => (
            <img
              className="img-fluid"
              src={node.data.target.fields.file.url}
              alt="img"
            />
          ),
        },
      };
      const ret = {
        welcomeContent: documentToReactComponents(
          entry.fields.freeTrialWelcomeText,
          options
        ),
        thankYouHeader: entry.fields.thankYouPageContentHeader,
        thankYouContent: documentToReactComponents(
          entry.fields.thankYouPageContent
        ),
        inactiveFreeTrialText: documentToReactComponents(
          entry.fields.inactiveFreeTrialText
        ),
        active: entry.fields.freeTrialActive,
      };
      return ret;
    }
  }, [contentfulEntry]);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const queryContentfulEntry = params.get("entity");
    const couponParam = params.get("c");
    setCoupon(couponParam);
    const checkoutEnabledParam = params.get("ce");
    const emailParam = params.get("e");
    // console.log("🚀 ~ useEffect ~ emailParam:", emailParam);
    setCheckoutEnabled(!!checkoutEnabledParam);
    if (emailParam) {
      setEmail(String(emailParam));
    }

    if (queryContentfulEntry) setContentfulEntry(queryContentfulEntry);
    else setContentfulEntry("1XeMTSgmKvjNn9XtrFLLfv"); // default entry ID for both dev and prod
  }, []);

  const scrollToForm = useCallback(
    (focusOnEmail = false) => {
      if (checkoutEnabled) {
        checkoutRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end",
        });
        setFreeTrialFormCompleted(true);
      } else {
        formRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end",
        });
      }

      if (focusOnEmail && !checkoutEnabled) {
        // console.log("attempting focus");
        emailRef.current?.focus();
        setEmailFocused(true);
      }
    },
    [checkoutEnabled]
  );

  useEffect(() => {
    // console.log("Checkout ref ", checkoutRef.current);
    if (checkoutEnabled) {
      // console.log("scrolling to form");
      scrollToForm(false);
    }
  }, [checkoutEnabled, scrollToForm]);

  async function validateEmailAndSend() {
    setLoading(true);
    let validateEmail = email?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (validateEmail) {
      try {
        // Checking if user already exists in DB
        const dbUser = await UserApi.getDBUser(String(email), {
          sync: false,
        });

        if (dbUser) {
          // console.log("User exists");
          setLoading(false);
          setEmailError(
            "It seems that this email is already used. Please sign-in to continue."
          );
        }
      } catch (e) {
        // User not found (which we want), proceeding to add user to Mailchimp
        // This calls the ChargebeeHook lambda which, in spite of his name, has nothing to do with chargebee. It just sends the user info to Mailchimp.
        Axios.post(process.env.REACT_APP_API_ENDPOINT + "/chargebee", {
          email,
          url:
            process.env.REACT_APP_DOMAIN +
            "/freetrial?c=" +
            coupon +
            "&ce=1&e=" +
            encodeURIComponent(String(email)),
          event_type: "free_trial_from_website",
        })
          .then((res) => {
            // console.log("send to mailchimp: ", res);
            setLoading(false);
            setCheckoutEnabled(true);
            setFreeTrialFormCompleted(true);
          })
          .catch((e) => {
            // console.log("problem sending to mailchimp: ", e);
            setEmailError("A problem has occured, please try again later.");
            setLoading(false);
          });
      }
    } else {
      setEmailError("Please enter a valid email address.");
      setLoading(false);
    }
  }

  return (
    <>
      <Title value="Free Trial" />
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "104px",
          left: 0,
          top: 0,
          background:
            "linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0) 100%)",
          pt: 2,
          pl: isMobile ? 1 : 10,
          pr: isMobile ? 1 : 10,
          zIndex: 15,
        }}
      >
        <Grid container xs={12}>
          <Grid xs={6} justifyContent={"flex-start"} sx={{ textAlign: "left" }}>
            <Box
              sx={{
                maxWidth: "169px",
                maxHeight: "75px",
                mx: 0,
              }}
            >
              <Logo height={"53px"} />
            </Box>
          </Grid>
          <Grid
            xs={6}
            alignItems={"right"}
            sx={{
              textAlign: "right",
              pr: isMobile ? 2 : 0,
              ml: isMobile ? -2 : 0,
              isolation: "isolate",
            }}
          >
            <Button
              color="darkPurple"
              type="button"
              className="tryitfree"
              onClick={() => scrollToForm(true)}
              sx={{
                padding: isMobile ? "15px 5px" : "15px 30px",
                minWidth: isMobile ? "200px" : "64px",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              Try it free!
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Elements stripe={stripe}>
        <Box
          sx={{
            backgroundColor: "#121212",
            minHeight: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "1119px",
              width: "100%",
              background: `url('${GrainBg}')`,
              backgroundPosition: "top right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              mixBlendMode: "overlay",
              zIndex: 2,
            }}
          ></Box>
          <Box
            sx={{
              height: "1800px",
              marginTop: "-1119px",
              background: `url('${Ellipse}')`,
              backgroundPosition: "top right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#121212",
              zIndex: 1,
            }}
          ></Box>

          <Box
            sx={{
              height: isMobile ? "500px" : "800px",
              marginTop: "-1800px",
              background: `url('${ImagesCover}')`,
              backgroundPosition: isMobile
                ? "top -50px right -100px"
                : "top -150px right -200px",
              backgroundSize: isMobile ? "cover" : "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 3,
              isolation: "isolate",
            }}
          ></Box>
          <Container
            sx={{
              height: "100%",
              marginTop: isMobile ? "-200px" : "-600px",
              zIndex: 5,
              position: "relative",
              isolation: "isolate",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <Typography
                variant="subtitle2"
                component="div"
                fontWeight="regular"
                sx={(theme) => ({
                  pt: 2,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.disabled,
                })}
              >
                Internet Radio. Non-profit.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography variant={"h1"} component="h1" fontWeight="bold">
                Laid-Back Jazz
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "500px",
                mt: 2,
              }}
            >
              <Typography variant={isMobile ? "body2" : "body1"}>
                Feel your shoulders relax with The Jazz Groove's gentle swing of
                music, featuring artists like Miles Davis, Duke Ellington,
                Wynton Marsalis, and thousands more. Try it free to enjoy Hi-Res
                Audio, Unlimited Access, Pure Music & No Talk, Song Skips &
                more!
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography
                variant={isMobile ? "h2" : "h1"}
                component="h1"
                fontWeight="bold"
              >
                <Button
                  color="darkPurple"
                  type="button"
                  className="tryitfree"
                  onClick={() => scrollToForm(true)}
                  sx={{
                    padding: "15px 30px",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  Try it free!
                </Button>
              </Typography>
            </Box>
          </Container>
        </Box>
        {!isMobile && (
          <Fade in={!hasScrolledDown}>
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: 2,
                isolation: "isolate",
                top: 0,
              }}
            >
              <Fade in={paused}>
                <div
                  style={{
                    position: "absolute",
                    right: "50%",
                    bottom: "110px",
                    isolation: "isolate",
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      width: "193px",
                      marginRight: "-96px",
                      height: "58px",
                    }}
                  >
                    <img src={pressPlayCircular} alt="Press Play" />
                  </div>
                </div>
              </Fade>
            </div>
          </Fade>
        )}

        <Grid
          container
          height={isMobile ? "700px" : "615px"}
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            zIndex: 5,
            isolation: "isolate",
            mt: 20,
            overflow: "visible",
            background: `#5B64B2`,
            borderRadius: "18px",
          }}
        >
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              py: isMobile ? 1 : 5,
              px: isMobile ? 3 : 12,
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontFamily: "futura-pt",
                fontWeight: 400,
                fontSize: isSmallMobile
                  ? "2.5em"
                  : isSmallDesktop
                  ? "3em"
                  : "3.5em",
                margin: "0 auto",
              }}
            >
              Member Benefits
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            alignItems="center"
            sx={{
              pt: isMobile ? 2 : 10,
              pl: 3,
              pr: isMobile ? 3 : 12,
              pb: isMobile ? 2 : 0,
              textAlign: isMobile ? "left" : "right",
            }}
          >
            <Typography
              variant={"subtitle2"}
              component="text"
              fontWeight="regular"
            >
              <b>Try it free</b> for one month to unlock:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              sx={{
                maxWidth: "1000px",
                margin: isMobile ? "0 10px" : "0 auto",
              }}
            >
              <Grid item xs={12} md sx={{ px: 1, pb: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumSilenceIcon}
                  title="Just the music"
                  body={
                    <>
                      No interruptions from promotional messages or fund drives.
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} md sx={{ px: 1, py: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumQualityIcon}
                  title="Hi-Res Audio"
                  body="Audiophile fidelity (Lossless FLAC)."
                />
              </Grid>
              <Grid item xs={12} md sx={{ px: 1, pt: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumSkipIcon}
                  title="Song Skips"
                  body={<>Want to listen to a different song? Click skip!</>}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md
                sx={{ px: 1, pt: isMobile ? 2 : 0, mt: isMobile ? 2 : 0 }}
              >
                <Perk
                  icon={UnlimitedAccessIcon}
                  title="Unlimited Access"
                  body={
                    <>
                      Your listening won’t be be capped as it is for
                      non-members.
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ maxWidth: "lg", margin: "0 auto", pt: 15, overflow: "visible" }}
        >
          <Grid
            item
            sm={12}
            height={"615px"}
            sx={{
              background: `url('${CardsBg}')`,
              borderRadius: "18px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                paddingTop: "50px",
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  component="div"
                  fontWeight="regular"
                  sx={(theme) => ({
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.disabled,
                    fontWeight: 500,
                  })}
                >
                  15,000+ songs across 5 unique jazz radio stations
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                  }}
                >
                  Jazz Legends & Rising Stars
                </Typography>
              </Box>
              <Box
                sx={{
                  pt: 2,
                  maxWidth: "700px",
                  margin: "auto",
                  mb: 10,
                }}
              >
                <Typography variant={isMobile ? "body1" : "body1"}>
                  Each hour of music is meticulously-curated and
                  artfully-blended from a rare collection of 15,000+ jazz
                  recordings spanning 8-decades
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Box sx={{ mt: isMobile ? "-220px" : "-330px" }}>
          <Carousel
            responsive={responsive}
            centerMode
            autoPlay={true}
            autoPlaySpeed={0}
            pauseOnHover={false}
            transitionDuration={2000}
            customTransition="transform 2s ease-in-out"
            infinite
            partialVisible={false}
            itemClass={styles.carouselPadding}
            arrows={false}
          >
            {legendsItems}
          </Carousel>
        </Box>
        <Box
          sx={{
            height: "4000px",
            marginTop: "-300px",
            background: `url('${EllipseBottom}')`,
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#121212",
            zIndex: 1,
          }}
        ></Box>
        <Box
          sx={{
            height: "819px",
            marginTop: "-819px",
            width: "100%",
            background: `url('${GrainBg}')`,
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            mixBlendMode: "overlay",
            zIndex: 2,
          }}
        ></Box>
        <Box
          sx={{
            margin: "-3800px auto 0",
            maxWidth: "lg",
          }}
        ></Box>
        <Grid
          container
          height={
            isSmallMobile
              ? "1150px"
              : isMobile
              ? "1000px"
              : isSmallDesktop
              ? "875px"
              : "740px"
          }
          sx={{
            maxWidth: "lg",
            margin: isMobile ? "220px auto 0" : "220px auto 0",
            overflow: "visible",
            zIndex: 5,
            isolation: "isolate",
            background: `#3C3C3C`,
            borderRadius: "18px",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                paddingTop: "50px",
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mt: 1, mx: isMobile ? "20px" : "240px" }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: isMobile ? "2em" : "3.5em",
                  }}
                >
                  Listen To The Jazz Groove On All Your Devices
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  pt: 2,
                  maxWidth: "1000px",
                  margin: "auto",
                  mb: 4,
                }}
              >
                <Device
                  icon={IoMdPhonePortrait}
                  title={"Smartphones"}
                  body={"iPhone & Android"}
                />
                <Device
                  icon={HiMiniComputerDesktop}
                  title={"Desktops"}
                  body={"PC & Mac"}
                />
                <Device
                  icon={FaTabletAlt}
                  title={"Tablets"}
                  body={"iOS & Android"}
                />
                <Device
                  icon={BsFillSpeakerFill}
                  title={"Speakers"}
                  body={"TV & Audio"}
                />
                <Device icon={SiSonos} title={""} body={""} size={120} />
                <Device icon={SiAmazonalexa} title={"Amazon Alexa"} body={""} />
                <Device icon={SiTunein} title={"TuneIn"} body={""} />
                <Device image={Heos} title={""} height="50px" body={""} />
              </Grid>
              <Grid
                container
                sx={{
                  pt: 2,
                  maxWidth: "400px",
                  margin: "0 auto",
                  mb: 4,
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 400,
                    fontSize: isMobile ? "1em" : "1.5em",
                    whiteSpace: "pre-line",
                    margin: "0 auto",
                  }}
                >
                  Plus Many Other Audio Devices & Apps{"\n"}
                  <Link
                    to={""}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToForm(true);
                    }}
                    className="tryitfree"
                  >
                    Try it free to unlock device setup!
                  </Link>
                </Typography>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 15,
            mb: 7,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                    lineHeight: "1.3em",
                  }}
                >
                  Jazz Groove listeners share why they donated
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "relative",
            width: "140%",
            marginLeft: "-20%",
            zIndex: 7,
          }}
        >
          <div className="slider-arrow">
            <Avatar
              className="arrow-btn-prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <NavigateBeforeIcon sx={{ color: "white" }} />
            </Avatar>
            <Avatar
              className="arrow-btn-next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              <NavigateNextIcon sx={{ color: "white" }} />
            </Avatar>
          </div>

          <Slider
            ref={sliderRef}
            infinite
            adaptiveHeight={false}
            variableWidth={false}
            arrows={false}
            centerMode
            centerPadding={"0px"}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={1}
          >
            {testimonialsItems}
          </Slider>
        </Box>
        <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 20,
            mb: 0,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                id="form"
                sx={{
                  mt: 2,
                  display: !loading ? "block" : "none",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3em",
                    lineHeight: "1.3em",
                  }}
                >
                  {(emailFocused && (
                    <>
                      Enter your email address below to try Unlimited Access,
                      Pure Music/No Talk, Hi-Res Audio & More for free!
                    </>
                  )) || (
                    <>
                      Try it free for Unlimited Access, Pure Music/No Talk,
                      Hi-Res Audio & More!
                    </>
                  )}
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>

        <Box
          sx={{
            pt: 5,
            pb: 8,
            margin: "0 auto 0",
            maxWidth: isMobile ? "lg" : "66%",
            zIndex: 5,
          }}
        >
          {loading && <Spinner />}

          {freeTrialDoc?.active && coupon && (
            <>
              {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkoutEnabled}
                      onChange={() => {
                        setCheckoutEnabled(!checkoutEnabled);
                      }}
                      style={{
                        display:
                          !loading && !guestFormSuccess ? "block" : "none",
                      }}
                    />
                  }
                  style={{ marginBottom: 0 }}
                  label={
                    <span
                      style={{
                        textTransform: "initial",
                        display:
                          !loading && !guestFormSuccess ? "block" : "none",
                      }}
                    >
                      I'd like to sign up for my free trial now
                    </span>
                  }
                /> */}
              <div
                ref={checkoutRef}
                style={{
                  display: checkoutEnabled && !loading ? "block" : "none",
                }}
              >
                <CheckoutStepper
                  isMobile={isMobile}
                  setShowLanding={(val) => {
                    if (isMobile) setShowLanding(val);
                  }}
                  freeTrial={true}
                  email={email}
                  freeTrialFormCompleted={freeTrialFormCompleted}
                ></CheckoutStepper>
              </div>

              <div
                style={{
                  display: checkoutEnabled || loading ? "none" : "block",
                  margin: isMobile ? 10 : "auto",
                }}
              >
                <FormControl
                  fullWidth
                  sx={{ mt: 1 }}
                  onSubmit={() => validateEmailAndSend()}
                >
                  <InputLabel htmlFor="email">Enter Email address</InputLabel>
                  <OutlinedInput
                    id="email"
                    inputRef={emailRef}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onBlur={(e) => {
                      setEmail(e.target.value);
                    }}
                    onFocus={(e) => {
                      // console.log("focused");
                      setEmail(e.target.value);
                    }}
                    required
                    label="Email"
                  />
                  {emailError && (
                    <Alert severity="error" sx={{ mt: 1 }}>
                      {emailError}
                    </Alert>
                  )}

                  <Button
                    color="darkPurple"
                    type="submit"
                    className="getstarted"
                    onClick={() => validateEmailAndSend()}
                    sx={{
                      padding: isMobile ? "15px 5px" : "15px 30px",
                      minWidth: isMobile ? "200px" : "64px",
                      mt: "20px",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    Get Started!
                  </Button>
                </FormControl>
              </div>
            </>
          )}
        </Box>

        {/* <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 5,
            mb: 20,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
              zIndex: 7,
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
                display: "flex-end",
              }}
            >
              <Box sx={{ mt: 2, mb: 4 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                    lineHeight: "1.3em",
                  }}
                >
                  Frequently Asked Questions
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Button
                  color="darkPurple"
                  component={Link}
                  to={"/devices"}
                  sx={{
                    padding: "20px 50px",
                    margin: isSmallMobile ? "10px auto" : "0 0 0 30px",
                    fontSize: "1.5em",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  Devices FAQ
                </Button>

                <Button
                  color="darkPurple"
                  component={Link}
                  to={"/help"}
                  sx={{
                    padding: "20px 50px",
                    margin: isSmallMobile ? "10px auto" : "0 0 0 30px",
                    fontSize: "1.5em",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  General FAQ
                </Button>
              </Box>
            </Container>
          </Grid>
        </Grid> */}
      </Elements>
      {/* need to keep this div here for scrolling */}
      <div style={{ height: "50px" }}></div>
      <div ref={formRef}></div>
      <Box sx={{ zIndex: 10, position: "relative" }}>
        <Footer />
      </Box>
      <Player />
    </>
  );
};

export default FreeTrial;
