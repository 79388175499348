import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import {
  Button,
  css,
  Fade,
  GlobalStyles,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { useAppContext } from "Context";
import Song from "./Song";
import {
  audioPlayer,
  currentMetadata,
  BLOCK,
  activeChannelId,
  currentEntry,
  setMaxQuality,
} from "AudioPlayer";
import sendLog from "Utils/Logger";
import PremiumAlert from "Utils/PlayerAlert";
import { isPremiumPlus, isPremium } from "Utils/Account/Plan";
import { useAuth0 } from "react-auth0-spa";
import { toast } from "react-toastify";
import { AUTH0_USER_METADATA_KEY, LOW_QUALITY } from "Utils/Constants";
import * as Sentry from "@sentry/react";
import { maxListenText } from "App";
import History from "Utils/Routing/History";
import PlayerControls from "./PlayerControls";
import PlayerSettingsButton from "./PlayerSettingsButton";
import PlayerActions, { PlayerVolume } from "./PlayerActions";
import PlayerDrawer from "./PlayerDrawer";
import * as S from "./Player.styles";
import { PlayerProps, PlayerStateProps } from "./types";
import SkipAlert from "Utils/SkipAlert";
import ConfirmationDialog from "../ConfirmationDialog";
import { DEV_STREAMS_URL } from "../Checkout/Constants";
import MusicStoppedDialog from "./MusicStoppedDialog";
import { LocalStorageItem } from "Utils/Constants/LocalStorageItem";

const RootStyles = (
  <GlobalStyles
    styles={(theme) => css`
      :root {
        --jg-player-height: ${theme.jgComponents.Player.height}px;

        ${theme.breakpoints.down("md")} {
          --jg-player-height: ${theme.jgComponents.Player.heightMobile}px;
        }
      }
    `}
  />
);

const Player = (props: PlayerStateProps & PlayerProps) => {
  const { play, skip, forceMobileView = false } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { paused } = useAppContext();
  const { loading } = useAuth0();

  // The drawer cannot be closed when forcing mobile view.
  const [drawerOpen, setDrawerOpen] = useState(forceMobileView);

  const isMobileView = mobile || forceMobileView;

  return (
    <>
      <PlayerDrawer {...props} open={drawerOpen} setOpen={setDrawerOpen} />

      <S.Container
        className={clsx(
          S.classes.root,
          paused && `${S.classes.root}--paused`,
          `${S.classes.root}--drawer-${drawerOpen ? "open" : "closed"}`,
          "mui-fixed"
        )}
        square
        component="aside"
        elevation={3}
      >
        <S.Content className={S.classes.content}>
          <Fade in={!drawerOpen} mountOnEnter unmountOnExit>
            <Song
              className={S.classes.song}
              direction="horizontal"
              size={isMobileView ? "sm" : "lg"}
              actionButton={
                !isMobileView && <PlayerSettingsButton {...props} />
              }
              sx={{ flex: 1 }}
              AlbumArtProps={{
                variant: "player",
                bordered: true,
                expandable: true,
                sx: {
                  display: isMobileView ? "none" : undefined,
                },
              }}
            />
          </Fade>

          <PlayerControls
            loading={loading}
            paused={paused}
            play={play}
            skip={skip}
            size={isMobileView ? "sm" : "lg"}
            leftButton={isMobileView && <PlayerVolume {...props} />}
            sideButtonsHidden={isMobileView && !drawerOpen}
          />

          <Fade in={!(isMobileView && drawerOpen)} mountOnEnter unmountOnExit>
            <PlayerActions
              {...props}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          </Fade>
        </S.Content>
      </S.Container>
    </>
  );
};

const PlayerContainer = (props: PlayerProps) => {
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [, setPlayStart] = useState(-1);
  const [qualityPreference, setQualityPreference] = useState<
    AudioQuality | any
  >("");
  const Context = useAppContext();
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [premiumAlertOpen, setPremiumAlertOpen] = useState(false);
  const [skipAlertOpen, setSkipAlertOpen] = useState(false);
  const [showBlockDialog, setShowBlockDialog] = useState(false);
  const [devPlayerSrc, setDevPlayerSrc] = useState(DEV_STREAMS_URL.mix1);
  const isDev = process.env.REACT_APP_NODE_ENV === "dev";
  const isFreeTrial =
    window.location.pathname.slice(1) === "free" ||
    window.location.pathname.slice(1) === "freetrial";

  useEffect(() => {
    const preferredQuality =
      user?.[AUTH0_USER_METADATA_KEY]?.preferences?.quality;
    const localStorageQuality = localStorage.getItem(
      LocalStorageItem.audioQuality
    ) as AudioQuality;

    if (
      isAuthenticated &&
      user &&
      user.plan &&
      (preferredQuality || localStorageQuality)
    ) {
      const quality =
        (isPremium(user.plan) && localStorageQuality) ||
        preferredQuality ||
        LOW_QUALITY;
      setMaxQuality(quality);
      setQualityPreference(quality);
    } else {
      setQualityPreference(LOW_QUALITY);
    }
  }, [isAuthenticated, user]);

  const handlePlay = () => {
    if (!Context.external) {
      try {
        if (!Context.paused) {
          pause();
        } else {
          play();
        }
      } catch (e) {
        Sentry.captureException(e);
        if (Context.activeChannel === 1) window.open("/jazzgroove-mix-2.m3u");
        else window.open("/jazzgroove-mix-1.m3u");
      }
    } else {
      if (Context.activeChannel === 1) window.open("/jazzgroove-mix-2.m3u");
      else window.open("/jazzgroove-mix-1.m3u");
    }
  };

  const play = () => {
    if (!audioPlayer) return;
    if (BLOCK) {
      setShowBlockDialog(true);
    }
    audioPlayer.play();
    currentMetadata.endTime = null;
    sendLog("play", currentMetadata);
    ReactGA.event({
      category: "player",
      action: "play",
      label: activeChannelId,
    });
    ReactGA.event({
      category: "player",
      action: "streamquality",
      label: activeChannelId,
      value: (isAuthenticated
        ? user[AUTH0_USER_METADATA_KEY]?.preferences?.quality || LOW_QUALITY
        : LOW_QUALITY) as any,
    });
    setPlayStart(parseInt((new Date().getTime() / 1000) as any));
  };

  const pause = () => {
    if (!audioPlayer) return;
    if (!Context.paused) {
      audioPlayer.pause();
      currentMetadata.endTime = new Date().toISOString();
      sendLog("pause", currentMetadata);
    }
    setPlayStart(-1);
  };

  const skipSong = async () => {
    if (!audioPlayer) return;
    ReactGA.event({
      category: "player",
      action: "skipattempt",
      label: activeChannelId,
      value: (currentEntry &&
      currentEntry.Asset &&
      currentEntry.Asset.tags &&
      currentEntry.Asset.tags.TITLE
        ? currentEntry.Asset.tags.TITLE + " - " + currentEntry.Asset.tags.ARTIST
        : "") as any,
    });
    try {
      if (
        (isAuthenticated && !isPremium(user.plan)) ||
        (!isPremiumPlus(user.plan) &&
          currentEntry &&
          currentEntry.Asset &&
          currentEntry.Asset.tags &&
          currentEntry.Asset.tags["jg-voiceover"] === "FD")
      ) {
        setPremiumAlertOpen(true);
      } else if (isPremium(user.plan) || isFreeTrial) {
        let skips: any = localStorage.getItem("skips");
        let date;
        if (skips) {
          skips = JSON.parse(skips);
          if (
            skips.count[Context.activeChannel] === 6 &&
            skips.resetTime > new Date().getTime()
          ) {
            setSkipAlertOpen(true);
          } else {
            if (skips.resetTime < new Date().getTime()) {
              date = new Date();
              date.setHours(date.getHours() + 1, 0, 0, 0);
              skips.resetTime = date.getTime();
              skips.count = [0, 0, 0, 0, 0];
              skips.count[Context.activeChannel] = 1;
            } else {
              skips.count[Context.activeChannel] =
                skips.count[Context.activeChannel] + 1;
            }
            skip();
          }
        } else {
          date = new Date();
          date.setHours(date.getHours() + 1, 0, 0, 0);
          skips = {
            count: [0, 0, 0, 0, 0],
            resetTime: date.getTime(),
          };
          skips.count[Context.activeChannel] = 1;
          skip();
        }
        localStorage.setItem("skips", JSON.stringify(skips));

        if (isFreeTrial) {
          toast(
            <div>
              You are experiencing one of our many premium features, skipping up
              to 6 songs every hour. <br />
              Try JazzGroove free to unlock them all!
            </div>,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              toastId: "tryfree",
            }
          );
        }

        return true;
      } else setPremiumAlertOpen(true);
    } catch (e) {
      setPremiumAlertOpen(true);
    }
    return false;
  };

  const skip = () => {
    if (!audioPlayer) return;
    currentMetadata.endTime = new Date().toISOString();
    sendLog("skip", currentMetadata);
    audioPlayer.seekQueueRelative(1);
    ReactGA.event({
      category: "player",
      action: "skipsuccess",
      label: activeChannelId,
      value: (currentEntry.Asset.tags.TITLE
        ? currentEntry.Asset.tags.TITLE + " - " + currentEntry.Asset.tags.ARTIST
        : "") as any,
    });
  };

  return (
    <>
      {isDev && !isFreeTrial && (
        <S.DevPlayer>
          <button onClick={() => window.open("/jazzgroove-mix-1.m3u")}>
            Download Mix1 m3u
          </button>
          <button onClick={() => window.open("/jazzgroove-mix-2.m3u")}>
            Download Mix2 m3u
          </button>
          <button onClick={() => setDevPlayerSrc(DEV_STREAMS_URL.mix1)}>
            Listen to Mix1
          </button>
          <button onClick={() => setDevPlayerSrc(DEV_STREAMS_URL.mix2)}>
            Listen to Mix2
          </button>
          <iframe
            title="DEV-PLAYER"
            src={devPlayerSrc}
            width="360"
            height="320"
            scrolling="no"
            frameBorder="0"
            allow="autoplay"
          ></iframe>
        </S.DevPlayer>
      )}

      <MusicStoppedDialog
        open={true}
        onClose={() => {}}
        onClick={() => window.location.reload()}
        onClickPlay={handlePlay}
        showMusicStoppedDialog={Context.musicStopped}
      />

      {RootStyles}
      <Player
        {...props}
        muted={muted}
        setMuted={setMuted}
        volume={volume}
        setVolume={setVolume}
        qualityPreference={qualityPreference}
        setQualityPreference={setQualityPreference}
        play={handlePlay}
        skip={skipSong}
        pause={pause}
      />

      <PremiumAlert
        popup={Context.popup}
        open={premiumAlertOpen}
        onClose={() => setPremiumAlertOpen(false)}
      />
      <SkipAlert open={skipAlertOpen} onClose={() => setSkipAlertOpen(false)} />
      <ConfirmationDialog
        title={maxListenText}
        open={showBlockDialog}
        cancelText="Donate"
        confirmText="Learn More"
        onClose={(confirmed) => {
          setShowBlockDialog(false);
          if (confirmed) {
            History.push("/premium");
          } else {
            window.open("/checkout", "_blank");
          }
        }}
      >
        <Button onClick={() => loginWithRedirect()}>
          Already donated? SIGN IN to your account.
        </Button>
      </ConfirmationDialog>
    </>
  );
};

export default PlayerContainer;
