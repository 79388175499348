import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Title from "./Components/Title";
import { useAuth0 } from "react-auth0-spa";
import { parseQSValue } from "Utils/Routing/QueryString";
import History from "Utils/Routing/History";
import { LoginQueryParams } from "Utils/Routing/LoginRedirect";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import JGLink from "Pages/Components/JGLink";
import SignInButton from "Pages/Components/SignInButton";
import { FreeTrialDocDto, SuccessfulDonation } from "../../types/Donate";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  CONTENTFUL_FREE_TRIAL_ENTRY_ID,
  CONTENTFUL_DONATE_DOC_ENTRY_ID,
} from "Utils/Constants";
import ContentfulClient from "Utils/ContentfulClient";
import { useAsync } from "react-use";

interface ThankYouProps {
  location: {
    state?: SuccessfulDonation;
  };
}

const DefaultContent = ({
  customer,
  signUp,
  entityDoc,
}: Pick<SuccessfulDonation, "customer" | "signUp"> & {
  entityDoc?: {
    thankYouHeader: string;
    thankYouContent: React.ReactNode;
  };
}) => {
  const loginQueryParams: LoginQueryParams = { redirect: "/" };

  return (
    <>
      {!entityDoc && (
        <>
          <p>
            Your tax-deductible donation supports everything you hear on The
            Jazz Groove. We can't do it without you and so appreciate your
            contribution.
          </p>

          {customer?.email && (
            <p>
              We emailed your receipt to <strong>{customer.email}</strong>. Be
              sure to use that email address when you{" "}
              <JGLink to={`/login?${queryString.stringify(loginQueryParams)}`}>
                sign in
              </JGLink>{" "}
              to your account here at JazzGroove.org
            </p>
          )}
        </>
      )}

      {entityDoc && entityDoc.thankYouContent}

      <Box sx={{ maxWidth: 400, mx: "auto", mt: 5 }}>
        <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
          Not sure what to do next?
        </Typography>

        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{ alignItems: "center", margin: "auto" }}
        >
          <SignInButton
            redirect={loginQueryParams.redirect}
            fullWidth
            sx={{ flex: 1 }}
          >
            Sign In
          </SignInButton>

          <Button
            color="neutral"
            component={Link}
            to="/blog/free%20trial"
            fullWidth
            sx={{ flex: 1 }}
          >
            Get Started
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const GiftContent = ({ gift }: Required<Pick<SuccessfulDonation, "gift">>) => {
  return (
    <p>
      Your Gift Membership has successfully been emailed to{" "}
      <strong>{gift.gift_receiver.first_name}</strong> to claim.
    </p>
  );
};

const ThankYou = (props: ThankYouProps) => {
  const state = props.location?.state;
  const { customer, gift, signUp } = state || {};

  const { isAuthenticated, refreshUserSilently } = useAuth0();

  const [hasAccount] = useState(
    (props.location.state && props.location.state.hasOwnProperty("users")) ||
      isAuthenticated
  );

  useEffect(() => {
    if (!props.location.state) {
      History.push("/checkout");
      return;
    }

    refreshUserSilently();

    if (
      props.location.state.subscription.billing_period_unit === "month" &&
      !props.location.state.subscription.setup_fee
    ) {
      dataLayer.push({
        event: "donationrepeat",
        value: props.location.state.subscription.plan_unit_price / 100,
      });
    } else {
      dataLayer.push({
        event: "donationonetime",
        value: props.location.state.subscription.setup_fee
          ? props.location.state.subscription.setup_fee / 100
          : props.location.state.subscription.plan_unit_price / 100,
      });
    }
    // eslint-disable-next-line
  }, []);

  const { value: entityDoc } = useAsync(async () => {
    const entry = await ContentfulClient.getEntry<FreeTrialDocDto>(
      props.location.state?.freeTrial
        ? CONTENTFUL_FREE_TRIAL_ENTRY_ID
        : CONTENTFUL_DONATE_DOC_ENTRY_ID
    );
    const ret = {
      thankYouHeader: entry.fields.thankYouPageContentHeader,
      thankYouContent: documentToReactComponents(
        entry.fields.thankYouPageContent
      ),
    };
    return ret;
  }, []);

  const ThankYouHeader = useCallback(() => {
    return <>{entityDoc?.thankYouHeader || "Thank You"}</>;
  }, [entityDoc?.thankYouHeader]);

  if (!props.location.state) return null;
  return (
    <Container
      maxWidth="sm"
      sx={(theme) => ({ textAlign: "center", ...theme.mixins.pageContent })}
    >
      <Title value="Thank You" />
      <Typography variant="h1" sx={{ mb: 3 }}>
        <ThankYouHeader />
        {customer?.first_name && `, ${customer.first_name}`}!
      </Typography>

      {hasAccount && parseQSValue("redirect") === "sonos" ? (
        <Typography>Return to Sonos to login</Typography>
      ) : gift != null ? (
        <GiftContent gift={gift} />
      ) : (
        <DefaultContent
          customer={customer!}
          signUp={signUp!}
          entityDoc={entityDoc}
        />
      )}
    </Container>
  );
};

export default ThankYou;
