import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Grid, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import moment, { Moment } from "moment";
import "../../../../i18n/config";
import { getI18n, useTranslation } from "react-i18next";

export interface GiftInfo {
  sendNow: boolean;
  sendDate: Moment;
  firstName: string;
  lastName: string;
  email: string;
  note: string;
  isGift: boolean;
  valid: boolean;
}

export default function GiftRecipientStep(props: {
  giftInfo: GiftInfo;
  setGiftInfo: (giftInfo: GiftInfo) => void;
  onToggle?: (checked: Boolean) => void;
  forcedGift?: boolean;
}) {
  const { t } = useTranslation();
  const { forcedGift } = props;
  const [isGift, setIsGift] = useState(
    !!(props.giftInfo && props.giftInfo.isGift)
  );
  const [sendNow, setSendNow] = useState(
    props.giftInfo && props.giftInfo.sendNow
  );
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [sendDate, setSendDate] = useState(moment());
  const [minSendDate, setMinSendDate] = useState(moment());
  const [firstName, setFirstName] = useState(
    (props.giftInfo && props.giftInfo.firstName) || ""
  );
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(
    (props.giftInfo && props.giftInfo.lastName) || ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState(
    (props.giftInfo && props.giftInfo.email) || ""
  );
  const [emailError, setEmailError] = useState("");
  const [note, setNote] = useState(
    (props.giftInfo && props.giftInfo.note) || ""
  );
  const [noteError, setNoteError] = useState("");
  const form = useRef<HTMLFormElement>(null);

  const { language } = getI18n();
  var isJapan = false;
  var userLang = language;

  if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
    isJapan = true;

  useEffect(() => {
    // Refresh both dates to the latest time.
    setMinSendDate(moment().startOf("minute"));
    setSendDate(moment());
  }, [sendNow]);

  const { setGiftInfo: propsSetGiftInfo } = props;
  useEffect(() => {
    propsSetGiftInfo({
      sendNow,
      sendDate,
      firstName,
      lastName,
      email,
      note,
      isGift,
      valid: form?.current?.checkValidity() ?? false,
    });
  }, [
    isGift,
    sendNow,
    datePickerOpen,
    sendDate,
    firstName,
    lastName,
    email,
    note,
    propsSetGiftInfo,
  ]);

  const setForm = (
    el: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
    setValue: (value: string) => void,
    setError: (error: string) => void
  ) => {
    setValue(el.value);
    if (setError) {
      if (el.checkValidity() === false) {
        setError(el.validationMessage);
      } else {
        setError("");
      }
    }
  };

  const handleToggle = useCallback(() => {
    setIsGift(!isGift);
    if (props.onToggle) {
      props.onToggle(isGift);
    }
  }, [setIsGift, isGift, props]);

  return (
    <>
      <form ref={form}>
        {!isJapan && !forcedGift && (
          <FormControlLabel
            control={<Checkbox checked={isGift} onChange={handleToggle} />}
            label={
              <span style={{ textTransform: "initial" }}>
                {t("donationPage.giftDonation")}
              </span>
            }
          />
        )}
        <Grid
          container
          spacing={2}
          style={{ display: isGift ? "flex" : "none" }}
          sx={{ mt: 2 }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              label="Recipient first name"
              value={firstName}
              error={!!firstNameError}
              helperText={firstNameError}
              onBlur={(e) => setForm(e.target, setFirstName, setFirstNameError)}
              onChange={(e) =>
                setForm(e.target, setFirstName, setFirstNameError)
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Recipient last name"
              value={lastName}
              error={!!lastNameError}
              helperText={lastNameError}
              onBlur={(e) => setForm(e.target, setLastName, setLastNameError)}
              onChange={(e) => setForm(e.target, setLastName, setLastNameError)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Recipient email"
              value={email}
              error={!!emailError}
              helperText={emailError}
              onBlur={(e) => setForm(e.target, setEmail, setEmailError)}
              onChange={(e) => setForm(e.target, setEmail, setEmailError)}
              fullWidth
              required
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Note to Recipient"
              value={note}
              error={!!noteError}
              helperText={noteError || `${(note || "").length}/500`}
              onBlur={(e) => setForm(e.target, setNote, setNoteError)}
              onChange={(e) => setForm(e.target, setNote, setNoteError)}
              fullWidth
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendNow}
                  onChange={() => setSendNow(!sendNow)}
                />
              }
              componentsProps={{
                // @ts-ignore
                label: {
                  style: { marginBottom: 0 },
                },
              }}
              label="Send now"
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                inputFormat="MMMM Do LT"
                disablePast={true}
                disabled={sendNow}
                minDateTime={minSendDate}
                open={datePickerOpen}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                value={sendDate}
                onChange={
                  ((date: moment.Moment | null) => {
                    setSendDate(
                      date?.isSameOrAfter(moment()) ? date : moment()
                    );
                  }) as any
                }
                renderInput={(textFieldProps) => {
                  return (
                    <TextField
                      {...textFieldProps}
                      label="Date/time to send gift membership"
                      fullWidth
                      onClick={() => {
                        // If the user has opted to not send now, open the picker.
                        if (!sendNow) {
                          setDatePickerOpen(true);
                        }
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
