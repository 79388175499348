import React, { useContext, useEffect, useState } from "react";
import {
  BoxProps,
  CircularProgress,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { useHome } from "Hooks";
import { useAppContext } from "Context";
import { PLAYER_DEFAULT_STATION_ID } from "Utils/Constants";
import * as S from "./AlbumArt.styles";
import JGIcon from "Pages/Components/JGIcon";
import { IoExpand } from "react-icons/io5";
import AlbumModal from "Utils/AlbumModal";
import { StationContext } from "StationsContext/Context";

export type AlbumArtProps = BoxProps & {
  src: string;
  size?: string | number;
  variant?: "default" | "player";
  borderRadius?: string;
  bordered?: boolean;
  expandable?: boolean;
  alt?: string;
};

const AlbumArt = (props: AlbumArtProps) => {
  const {
    src,
    size,
    variant = "default",
    bordered = false,
    expandable = false,
    alt,
    ...rest
  } = props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const home = useHome();
  const isFreeTrial =
    window.location.pathname.slice(1) === "free" ||
    window.location.pathname.slice(1) === "freetrial";
  const { activeChannel: selectedStationId } = useAppContext();
  const { stations } = useContext(StationContext);
  const STATIONS = stations;

  // The default value depends on whether we're on the home page. On the home page, we default to expanded.
  const [expanded, setExpanded] = useState(expandable && isDesktop && home);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setExpanded(expandable && (home || isFreeTrial) && isDesktop);
  }, [expandable, home, isDesktop, isFreeTrial, setExpanded]);

  const toggleExpanded = () => {
    if (expandable && isDesktop) {
      setExpanded(!expanded);
    } else {
      setOpen(true);
    }
  };

  const bgImgSrc =
    src ||
    STATIONS[selectedStationId]?.defaultAlbumArtUrl ||
    STATIONS[PLAYER_DEFAULT_STATION_ID].defaultAlbumArtUrl;

  return (
    <>
      <S.Container
        ownerState={{ size }}
        className={clsx([
          S.classes.root,
          `${S.classes.root}--variant-${variant}`,
          expanded && `${S.classes.root}--expanded`,
          bordered && `${S.classes.root}--bordered`,
        ])}
        {...rest}
      >
        <S.ImageContainer onClick={toggleExpanded}>
          {/* Sits behind the album art, so it will automatically show if the image hasn't loaded. */}
          <S.LoadingContainer square>
            <CircularProgress />
          </S.LoadingContainer>

          <S.Image
            className={S.classes.image}
            style={{ backgroundImage: `url(${bgImgSrc})` }}
          />

          {expanded && (
            <Tooltip title="Expand" placement="top">
              <IconButton
                id="expand-album"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}
                sx={(theme) => ({
                  position: "absolute",
                  top: 0,
                  right: 0,
                  m: 0.5,
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover, &.Mui-focusVisible": {
                    backgroundColor: theme.palette.primary.light,
                  },
                })}
              >
                <JGIcon is={IoExpand} />
              </IconButton>
            </Tooltip>
          )}
        </S.ImageContainer>
      </S.Container>
      <AlbumModal
        src={bgImgSrc}
        alt={alt}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default AlbumArt;
