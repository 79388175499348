import { useEffect, useState } from "react";
import contentfulClient from "../Utils/ContentfulClient";
import { getI18n } from "react-i18next";

const useGetStationApi = () => {
  const [stations, setStation] = useState<any>();
  const isDev = process.env.REACT_APP_NODE_ENV === "dev";

  const parseStations = (stations: any) => {
    // TODO: REMOVE MAPPING ID AND CHANNELID BEFORE DEPLOYMENT
    let parsedStations = stations?.items.map((item: any) => {
      return {
        ...item.fields,
        id: item.fields.channelId,
        channelId: item.fields.id,
      };
    });

    if (isDev) {
      const filteredArray = parsedStations.filter(
        (el: any) => el.id !== "dev1" && el.id !== "dev2"
      );
      parsedStations = filteredArray;
    }

    const isFreeTrial =
      window.location.pathname.slice(1) === "free" ||
      window.location.pathname.slice(1) === "freetrial";

    if (isFreeTrial) {
      const filteredArray = parsedStations.filter(
        (el: any) => el.queryCode === "freetrial"
      );
      parsedStations = filteredArray;
    } else {
      const filteredArray = parsedStations.filter(
        (el: any) => el.queryCode !== "freetrial"
      );
      parsedStations = filteredArray;
    }

    console.log("Parsed Stations: ", parsedStations);

    parsedStations.sort((a: any, b: any) => a.order - b.order);

    return parsedStations;
  };

  const getStations = async () => {
    try {
      const { language } = getI18n();

      var isJapan = false;
      var userLang = language;

      if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
        isJapan = true;

      console.log("browser lang", userLang);

      const entries = await contentfulClient.getEntries({
        content_type: "channelBackgroundList",
        include: 2,
        locale: isJapan ? "ja-JP" : "en-US",
      });

      // console.log(entries);

      setStation(parseStations(entries));
    } catch (err) {
      console.error("Unable to refresh image gallery from network", err);
    }
  };

  useEffect(() => {
    getStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { stations };
};

export default useGetStationApi;
