import { Button, Dialog, DialogActions, DialogContent, useTheme, DialogContentText } from "@mui/material";
import { useState } from "react";

function FootnoteDialog({children, open, onClose}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent sx={{ pb: 2 }}>
                <DialogContentText variant="body2">
                    {children}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    color="neutral"
                    size="small"
                    onClick={onClose}
                    sx={{
                    maxWidth: 140,
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function FootnoteButton(props: {number: number, children: string | JSX.Element}) {
    const theme = useTheme()
    const [dialogOpen, setDialogOpen] = useState(false);
  
    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    
    return (
        <>
            <sup>
                <Button
                    variant="text"
                    onClick={openDialog}
                    sx={{
                        minWidth: 0,
                        padding: 0.25,
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                        lineHeight: "normal",
                        color: theme.palette.primary.light
                    }}
                >
                    {props.number ? props.number : "*"}
                </Button>
            </sup>

            <FootnoteDialog open={dialogOpen} onClose={closeDialog}>{props.children}</FootnoteDialog>
        </>
    )
}