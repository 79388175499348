import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PlanTierKey } from "./PlanTier";

export type PlanFeatureKey =
  | "silence-all"
  | "no-ads"
  | "silence-promo"
  | "skip"
  | "quality"
  | "unlimited"
  | "tax-deductible"
  | "surround-sound"
  | "physical-gift";

export interface PlanFeature {
  name: string;
  featureKey: PlanFeatureKey;
  footnote?: string | JSX.Element;
  // Lowest tier that the feature is included.
  lowestTier: PlanTierKey;
  number?: number;
}

function createFeature(
  name: string,
  featureKey: PlanFeatureKey,
  lowestTier: PlanTierKey,
  footnote?: string | JSX.Element,
  number?: number
): PlanFeature {
  return { name, featureKey, footnote, lowestTier, number };
}

/**
 * Order determines where the feature will show up in the list.
 */
export const PLAN_FEATURES: readonly PlanFeature[] = [
  createFeature(
    "Silence all fund drives",
    "silence-all",
    "premium-plus-lite",
    <>
      Fund Drive and promotional messages are silenced with the Jazz Groove
      Premium Plus plan on devices listed{" "}
      <Link component={RouterLink} to="/devices">
        here
      </Link>
      . Not all devices are listed - email us if you don’t see your device on
      the list (
      <Link href="mailto:support@jazzgroove.org">support@jazzgroove.org</Link>)
    </>,
    1
  ),
  createFeature("Skip songs", "skip", "premium"),
  createFeature(
    "Audiophile fidelity (Hi-res lossless FLAC)",
    "quality",
    "premium"
  ),
  createFeature("Unlimited access", "unlimited", "unlimited-listening"),
  createFeature(
    "Physical gift (T-Shirt, CD, etc.)",
    "physical-gift",
    "premium-plus",
    `We’ll ship the gift you choose when you donate monthly at the $20 level and up or at the one-time $100 level and up.
    If you’d like a gift with your Premium Plus monthly donation, click DONATE, select $20 or more per month, choose your gift & click CHECKOUT NOW.
    A gift is available on this page with your one-time donation of $100 and up.`,
    3
  ),
  createFeature(
    "Tax-deductible",
    "tax-deductible",
    "premium",
    `JazzGroove.org is a service of the 501(c)(3) non-profit organization, Foundation to Advance
    Jazz (EIN #81-1511864), therefore your donation is fully tax-deductible in the United States, minus
    the fair market value of any Thank You Gift you may request.`,
    2
  ),
] as const;

export const PLAN_FEATURES_WITH_FOOTNOTES = Object.freeze(
  PLAN_FEATURES.filter((feature) => !!feature.footnote)
);
