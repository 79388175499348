import { Alert, FormControl, Grid, Input, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectBubble from "../SelectBubble";
import { DonationType } from "Utils/Constants";
import NumberFormatInput, {
  NumberFormatInputChangeEvent,
} from "Pages/Components/Forms/NumberFormatInput";
import { DEFAULT_DONATION_AMOUNTS } from "Pages/Components/Checkout/Constants";
import "../../../../i18n/config";
import { useTranslation } from "react-i18next";

interface UpdateDonationAmountOptions {
  resetIfNotCustom?: boolean;
  event?:
    | NumberFormatInputChangeEvent
    | React.MouseEvent<HTMLDivElement, MouseEvent>;
}

const CUSTOM_AMOUNT_BUBBLE_INDEX = -1;

const DonationAmountStep = (props: {
  setDonationAmount: (amount: number) => void;
  isGift: boolean;
  gift: any;
  donationAmount: number;
  donationType: DonationType;
  setIsValid: (valid: boolean) => void;
  resetGift: () => void;
}) => {
  const donationAmounts = useMemo(() => {
    if (props.isGift) return [100_00];
    else if (props.donationType === DonationType.monthly)
      return [10_00, 15_00, 20_00, 25_00, 50_00, 100_00];
    else return [60_00, 75_00, 100_00, 150_00, 250_00, 500_00, 750_00, 1000_00];
  }, [props.donationType, props.isGift]);

  const [error, setError] = useState("");
  const { t } = useTranslation();

  /**
   * Index of the currently selected bubble in {@link donationAmounts}.
   * If the custom amount bubble is selected, value will be {@link CUSTOM_AMOUNT_BUBBLE_INDEX}.
   */
  const [selectedBubble, _setSelectedBubble] = useState(0);
  /**
   * Used to detect when a change is because of a parent or because of this component.
   */
  const [internalDonationAmount, setInternalDonationAmount] =
    useState<number>(0);
  const { donationAmount, setDonationAmount } = props;
  const [customAmountEmpty, setCustomAmountEmpty] = useState<boolean>(true);

  const { setIsValid: propsSetIsValid } = props;
  useEffect(() => {
    propsSetIsValid(!error);
  }, [error, propsSetIsValid]);

  const { resetGift, gift, donationType } = props;
  useEffect(() => {
    const validateGift = () => {
      // Removes the gift selected if the amount
      // is less than the monthly/one-time price of the item respectively
      const pricing = gift?.meta_data?.pricing;
      if (pricing) {
        const minimumDonation = (pricing.monthly || pricing.oneTime)?.minimum;
        if (minimumDonation > donationAmount) resetGift();
      }
    };

    setDonationAmount(donationAmount);
    validateGift();
  }, [donationAmount, donationType, gift, resetGift, setDonationAmount]);

  const updateDonationAmount = useCallback(
    (index: number, options?: UpdateDonationAmountOptions) => {
      let amount = donationAmount;
      if (index === CUSTOM_AMOUNT_BUBBLE_INDEX) {
        const event = options?.event;
        if (event) {
          const rawValue = (event as any)?.target?.value;
          setCustomAmountEmpty(!rawValue);
          const value = parseInt(rawValue.replace(/,/g, "")) || 0;
          amount = value * 100;
        } else if (options?.resetIfNotCustom) {
          amount = 0;
        }
      } else {
        amount = donationAmounts[index];
      }

      setDonationAmount(amount);
      setInternalDonationAmount(amount);
    },
    [donationAmount, setDonationAmount, donationAmounts]
  );

  const setSelectedBubble = useCallback(
    (index: number, options?: UpdateDonationAmountOptions) => {
      _setSelectedBubble(index);
      updateDonationAmount(index, options);
    },
    [updateDonationAmount]
  );

  // Runs when the props.donationAmount value changes, which occurs
  // either when the SetAmount function in this component runs
  // or when the prop is passed from another component such as Checkout.js when
  // a gift in the UI is selected
  useEffect(() => {
    setSelectedBubble(donationAmounts.indexOf(donationAmount));
    // Set error message if gift membership is selected and amount is less than $100
    if (
      props.isGift &&
      donationAmount < DEFAULT_DONATION_AMOUNTS[DonationType.oneTime]
    ) {
      setError("Gift memberships require $100 minimum");
      // Set error message if donation amount is less than $5
    } else if (
      !customAmountEmpty &&
      donationAmount < 10_00 &&
      selectedBubble === CUSTOM_AMOUNT_BUBBLE_INDEX
    ) {
      setError(
        "The amount entered is less than the minimum donation amount of $10"
      );
      // Else don't set an error message
    } else {
      setError("");
    }
  }, [
    donationAmount,
    donationAmounts,
    internalDonationAmount,
    props.isGift,
    selectedBubble,
    setSelectedBubble,
    customAmountEmpty,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        {donationAmounts.map((amount, index) => {
          return (
            <Grid item xs={4} sm={2} key={index}>
              <SelectBubble
                selected={selectedBubble === index}
                label={`$${amount / 100}`}
                onClick={() => setSelectedBubble(index)}
              />
            </Grid>
          );
        })}
        <Grid item xs={4}>
          {!props.isGift && (
            <SelectBubble
              selected={selectedBubble === CUSTOM_AMOUNT_BUBBLE_INDEX}
              label={
                <>
                  <FormControl fullWidth>
                    <Input
                      value={
                        selectedBubble === CUSTOM_AMOUNT_BUBBLE_INDEX &&
                        donationAmount > 0
                          ? donationAmount / 100
                          : ""
                      }
                      onClick={(event) =>
                        setSelectedBubble(CUSTOM_AMOUNT_BUBBLE_INDEX, {
                          event,
                          resetIfNotCustom: true,
                        })
                      }
                      onBlur={(event) => {
                        updateDonationAmount(CUSTOM_AMOUNT_BUBBLE_INDEX, {
                          event,
                        });
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      aria-label="custom-donation"
                      placeholder={t("donationPage.otherAmount")}
                      disableUnderline
                      inputComponent={NumberFormatInput as any}
                      inputProps={{
                        thousandSeparator: true,
                        allowNegative: false,
                        decimalScale: 0,
                      }}
                    />
                  </FormControl>
                </>
              }
              onClick={() => {}}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </Grid>
    </>
  );
};

export default DonationAmountStep;
