import React from "react";
import { List, ListProps } from "@mui/material";
import { PlanTierKey, PLAN_FEATURES } from "Utils/Constants";
import PlanFeatureItem, { PlanFeatureItemSize } from "./PlanFeatureItem";
import { getI18n } from "react-i18next";

export type PlanFeaturesListProps = ListProps & {
  tier: PlanTierKey;
  size?: PlanFeatureItemSize;
  giftEnabled: boolean;
};

const PlanFeaturesList = React.memo((props: PlanFeaturesListProps) => {
  const { tier, size = "medium", giftEnabled, ...rest } = props;

  const { language } = getI18n();

  var isJapan = false;
  var userLang = language;

  if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
    isJapan = true;

  const PLAN_FEATURES_FILTERED = PLAN_FEATURES.filter(
    (item) => !(isJapan && item.featureKey === "physical-gift")
  );

  return (
    <List dense {...rest}>
      {PLAN_FEATURES_FILTERED.map((feature, index) => (
        <PlanFeatureItem
          key={index}
          feature={feature}
          tier={tier}
          size={size}
          giftEnabled={giftEnabled}
        />
      ))}
    </List>
  );
});

export default PlanFeaturesList;
