import { useCallback, useEffect, useState } from "react";
import Axios, { AxiosResponse } from "axios";
import { RouteChildrenProps } from "react-router-dom";
import Title from "./Components/Title";
import DefaultLayout from "Containers/Layout/DefaultLayout";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PaypalProps } from "../../types/Donate";
import BannerBg from "Assets/checkout-banner.jpg";
// import MobileBg from "Assets/checkout-mobile-background.jpg";
import DonationAmountStep from "./Components/Checkout/Steps/DonationAmountStep";
import { DonationType } from "Utils/Constants/Donate";
import { getTokenSilently } from "react-auth0-spa";
import Spinner from "./Components/Spinner";

const PREFIX = "jg-checkout";
const classes = {
  root: PREFIX,
  subtitle: `${PREFIX}__subtitle`,
  subtitleStrongText: `${PREFIX}__subtitle__strong-text`,
};

const OneTimeCheckout = (props: PaypalProps & RouteChildrenProps) => {
  const [error] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [donationAmount, setDonationAmount] = useState(10000);
  const [donationAmountValid, setDonationAmountValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTinyMobile = useMediaQuery("(max-width: 450px)");

  const onSubmit = useCallback(async () => {
    if (donationAmountValid) {
      setLoading(true);
      setIsSubmitted(false);
      const accessToken = await getTokenSilently();

      const response = await Axios.post<any, AxiosResponse>(
        process.env.REACT_APP_API_ENDPOINT + "/checkout/one-time-url",
        {
          amount: donationAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        setCheckoutUrl(response.data);
        setIsSubmitted(true);
        setLoading(false);
      }
    }
  }, [donationAmount, donationAmountValid]);

  useEffect(() => {
    onSubmit();
  }, [donationAmount, onSubmit]);

  useEffect(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <DefaultLayout disablePlayer>
      {!isMobile && (
        <Box
          sx={{
            height: "200px",
            background: `linear-gradient(114.79deg, ${alpha(
              theme.palette.primary.main,
              0.72
            )} -2.66%, ${alpha(
              theme.palette.primary.dark,
              0.56
            )} 100%), url('${BannerBg}')`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="display1"
                sx={(theme) => ({
                  textTransform: "uppercase",
                  textShadow: theme.textShadows[1],
                })}
              >
                Donate
              </Typography>

              <Divider
                orientation="vertical"
                flexItem
                sx={{ pl: 2, borderColor: "text.disabled" }}
              />

              <Box sx={{ pl: 2, maxWidth: "50ch" }}>
                <Typography
                  variant="h5"
                  sx={(theme) => ({
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "regular",
                    lineHeight: 1.2,
                    textShadow: theme.textShadows[1],
                  })}
                >
                  <Box
                    className={classes.subtitle}
                    component="span"
                    sx={{
                      [`& .${classes.subtitleStrongText}`]: {
                        color: "primary.main",
                      },
                    }}
                  >
                    Everything you hear on The Jazz Groove is{" "}
                    <strong>funded by listeners</strong> like you.
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Box sx={{ maxWidth: isTinyMobile ? "350px" : "520px" }}>
          <Title value="Complete Donation" />
          <Typography variant="h1" sx={{ my: 2, textAlign: "center" }}>
            {error ? "An error has occurred" : "Confirm Your Donation"}
          </Typography>
          <DonationAmountStep
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            donationType={DonationType.oneTime}
            isGift={false}
            setIsValid={setDonationAmountValid}
            gift={{}}
            resetGift={() => {}}
          ></DonationAmountStep>

          {loading ? (
            <Spinner />
          ) : (
            !isSubmitted && (
              <Button
                color="primary"
                onClick={onSubmit}
                sx={{
                  padding: isMobile ? "15px 5px" : "15px 30px",
                  minWidth: isMobile ? "200px" : "64px",
                  marginTop: "10px",
                }}
              >
                Confirm
              </Button>
            )
          )}
          {donationAmount && donationAmountValid && isSubmitted ? (
            <iframe
              title="Checkout"
              src={checkoutUrl}
              width={isTinyMobile ? "350px" : "485px"}
              height={"600px"}
              scrolling="no"
              style={{
                border: 0,
                overflow: "hidden",
                marginTop: isMobile ? "20px" : "0px",
              }}
            ></iframe>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default OneTimeCheckout;
