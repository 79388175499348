import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import english from "./en/translation.json";
import japanese from "./ja/translation.json";

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    supportedLngs: ["ja", "en"],
    debug: true,
    fallbackLng: "en",
    resources: {
      en: {
        translation: english,
      },
      ja: {
        translation: japanese,
      },
    },
  });
