import React, { useEffect } from "react";
import { getI18n } from "react-i18next";
declare const window: any;
declare const google: any;

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
        autoDisplay: false,
        multilanguagePage: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    function setCookie(key, value, expiry) {
      var expires = new Date();
      expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
      document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
    }
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    const { language } = getI18n();

    var isJapan = false;
    var userLang = language;

    if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
      isJapan = true;

    if (isJapan) {
      setCookie("googtrans", "/en/ja", 1);
    } else {
      setCookie("googtrans", "/en/en", 1);
    }
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default GoogleTranslate;
