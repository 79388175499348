import React from "react";
import { Typography } from "@mui/material";

/**
 * The Co-Browse code is populated automatically in the `#upscope-support-code` selector.
 * @see https://cobrowse.io/
 */
const CoBrowseCode = () => {
  return (
    <Typography variant="caption" component="p" sx={{ mt: 2, color: "text.disabled" }}>
      <span id="upscope-support-code" />
    </Typography>
  );
};

export default CoBrowseCode;
