import React from "react";
import { Box, SxProps, Tooltip } from "@mui/material";
import JGIcon from "../../JGIcon";
import { IoPause, IoPlay, IoPlaySkipForward } from "react-icons/io5";
import PlayerControlsBackground from "./PlayerControlsBackground";
import * as S from "./PlayerControls.styles";
import { ControlsSize, PlayerControlsStyleProps } from "./types";
import clsx from "clsx";

interface PlayerControlsProps {
  loading: boolean;
  paused: boolean;
  skip: () => void;
  play: () => void;
  size?: ControlsSize;
  leftButton?: React.ReactNode;
  sideButtonsHidden?: boolean;
  sx?: SxProps;
}

const PlayerControls = (props: PlayerControlsProps) => {
  const {
    loading,
    paused,
    skip,
    play,
    leftButton,
    size = "lg",
    sideButtonsHidden = false,
    ...rest
  } = props;

  const ownerState: PlayerControlsStyleProps = {
    controlsSize: size,
    sideButtonsHidden,
    paused,
  };

  return (
    <S.Container
      className={clsx(
        S.classes.root,
        `${S.classes.root}--size-${size}`,
        `${S.classes.root}--side-buttons-${
          sideButtonsHidden ? "hidden" : leftButton ? "two" : "one"
        }`
      )}
      ownerState={ownerState}
      {...rest}
    >
      <S.Bar className={S.classes.bar} ownerState={ownerState}>
        <PlayerControlsBackground
          sideButtonsHidden={sideButtonsHidden}
          size={size}
        />

        {!sideButtonsHidden && (
          <>
            <S.LeftButton
              className={S.classes.leftButton}
              ownerState={ownerState}
            >
              {leftButton}
            </S.LeftButton>

            <Tooltip
              title={<Box sx={{ textAlign: "center" }}>Skip this song</Box>}
            >
              <S.SkipButton
                className={S.classes.skipButton}
                aria-label="Skip song"
                size={size === "sm" ? "small" : "large"}
                onClick={skip}
                ownerState={ownerState}
              >
                <JGIcon
                  is={IoPlaySkipForward}
                  fontSize={size === "sm" ? "small" : undefined}
                />
              </S.SkipButton>
            </Tooltip>
          </>
        )}
      </S.Bar>

      <S.PlayButton
        className={S.classes.playButton}
        loading={loading}
        variant="contained"
        size="large"
        aria-label={paused ? "Play" : "Pause"}
        onClick={() => {
          if (!loading) play();
        }}
        ownerState={ownerState}
      >
        <JGIcon
          is={paused ? IoPlay : IoPause}
          className={`${S.classes.playButton}__icon`}
          fontSize="inherit"
        />
      </S.PlayButton>
    </S.Container>
  );
};

export default PlayerControls;
